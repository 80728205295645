import React from 'react';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from './OrderPageContext';
import { ButtonCustom } from 'components/form-input/Button';
import { labels } from 'constants/labels';
import { Button } from 'components/button';

function FormButtons({ disabled, buttonAction, actionLabel, onSubmit }) {
  const {
    ordersPageData: { questionTypeSelection, greenButtonQuestionSelection },
    orderTypePath,
    dispatchOrderPageAction,
  } = useOrdersPagedata();
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const buttonLabelsAction = singleOrderData?.guid && orderTypePath === 'edit';
  const { confirmAndSendRequest } = labels;

  function handleCancelRequest() {
    dispatchOrderPageAction({
      type: 'order/setNavigationConfirmationModalOpen',
      payload: true,
    });
  }

  function handleGreenButtonStudyParametersRequest() {
    if (greenButtonQuestionSelection === 0) {
      dispatchOrderPageAction({
        type: 'order/setGreenButtonQuestionSelection',
        payload: 1,
      });
    } else {
      dispatchOrderPageAction({
        type: 'order/setGreenButtonQuestionSelection',
        payload: 0,
      });
    }
  }

  if(questionTypeSelection !== 3) {
    return (
      <div className="d-flex flex-wrap flex-md-row-reverse gap-10">
        <Button
          type="button"
          label={confirmAndSendRequest}
          disabled={disabled}
          onClick={onSubmit}
          buttonAction={buttonAction}
          actionLabel={actionLabel}
          id="btn-submit-order-button"
        />
        <Button
          type="button"
          outlined
          label={greenButtonQuestionSelection === 0
            ? labels.enterAsStudyParameters
            : labels.enterAsFreeText}
          onClick={handleGreenButtonStudyParametersRequest}
          id="btn-green-order"
        />
      </div>
    )
  }

  return (
    <div className="d-md-flex flex-md-justify-between flex-md-row-reverse">
      <ButtonCustom
        disabled={disabled}
        cssClass={`gradient-btn send-request-btn mt-md-0 mt-3 ${
          disabled ? 'btn-disabled' : ''
        }`}
        onClick={onSubmit}
        buttonAction={buttonAction}
        actionLabel={actionLabel}
        id="btn-submit-order-button"
      >
        {confirmAndSendRequest}
      </ButtonCustom>
      <ButtonCustom
        cssClass="cancel-btn mt-md-0 mt-3"
        onClick={handleCancelRequest}
        id="btn-cancel-order-button"
        buttonAction={
          buttonLabelsAction
            ? 'Discard Changes edit order clicked'
            : 'Cancel new PG order clicked'
        }
        actionLabel={
          buttonLabelsAction ? 'Edit order changes discarded' : 'New PG order canceled'
        }
      >
        {buttonLabelsAction ? 'Discard Changes' : 'Cancel Request'}
      </ButtonCustom>
    </div>
  );
}

export default FormButtons;