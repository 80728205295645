import React from 'react';
import StyledStatusButton from './StyledStatusButton';
import CustomTooltip from 'components/OverlayTooltip/CustomTooltip';
import { ImInfo } from 'react-icons/im';

function StatusButton({
  severity,
  className,
  label,
  tooltipcontent,
  tooltipcontentposition = 'bottom',
  ...rest
}) {
  return (
    <StyledStatusButton className={className} severity={severity} {...rest}>
      {label}
      {tooltipcontent && (
        <CustomTooltip
          position={tooltipcontentposition}
          content={<ImInfo size={15} />}
          info={tooltipcontent}
        />
      )}
    </StyledStatusButton>
  );
}

export default StatusButton;
