import React from 'react';
import { labels } from 'constants/labels';
import { ReactComponent as ChevronRightDanger } from 'assets/images/chevron-right-danger.svg';
import { CALENDY_LINK } from 'constants/Constants';
import { useSelector } from 'react-redux';
import { StyledErrorButton, StyledContainer, StyledErrorText } from './StyledErrorButton';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';

const ErrorSupport = () => {
  const { userData } = useSelector(({ userProfile }) => userProfile);
  const isPaidUser =
    userData?.subscription_information?.name?.toLowerCase() !== labels.free;
  const { dispatchOrderPageAction } = useOrdersPagedata();

  function handleClickForPaidUser() {
    dispatchOrderPageAction({
      type: 'order/setGreenButtonQuestionSelection',
      payload: 0,
    });

    dispatchOrderPageAction({ type: 'order/setQuestionTypeSelection', payload: 1 });
  }

  if (isPaidUser) {
    return (
      <StyledContainer>
        <div className="d-flex flex-wrap flex-column gap-16">
          <StyledErrorText>{labels.supportNotice}</StyledErrorText>
          <div className="d-flex flex-column flex-md-row flex-wrap gap-10">
            <StyledErrorButton
              variant="danger"
              className="txt w-700 text-danger-100"
              icon={<ChevronRightDanger className="buttonIcon" />}
              label={labels.submitYourQuestion}
              onClick={handleClickForPaidUser}
            />

            <a
              href={CALENDY_LINK}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'none',
                color: 'inherit',
                display: 'inline-block',
                maxWidth: 'fit-content',
              }}
            >
              <StyledErrorButton
                className="txt w-700 text-danger-100"
                label={labels.scheduleWithProvider}
              />
            </a>
          </div>
        </div>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <div className="d-flex flex-wrap flex-column gap-16">
        <StyledErrorText>{labels.supportNotice}</StyledErrorText>
        <div className="d-flex flex-column flex-md-row flex-wrap gap-10">
          <a
            href={labels.atroposContactUs}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'inline-block',
              maxWidth: 'fit-content',
            }}
          >
            <StyledErrorButton
              variant="danger"
              className="txt w-700 text-danger-100"
              icon={<ChevronRightDanger className="buttonIcon" />}
              label={labels.upgradeAccount}
            />
          </a>
          <a
            href={`mailto:${labels.supportEmail}`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'inline-block',
              maxWidth: 'fit-content',
            }}
          >
            {' '}
            <StyledErrorButton
              className="txt w-700 text-danger-100"
              label="Contact Support"
            />
          </a>{' '}
        </div>
      </div>
    </StyledContainer>
  );
};

export default ErrorSupport;
