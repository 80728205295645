import { Button } from 'components/button';
import styled from 'styled-components';

export const StyledErrorButton = styled(Button)`
  padding: 8px 12px;
  border: 0;
  border-radius: var(--border-radius-md);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  max-width: fit-content;
  font-size: 14px;
  line-height: 20px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }

  background: ${({ variant }) =>
    variant === 'danger' ? 'var(--bg-danger-light-100)' : 'transparent'};
  color: ${({ variant }) => (variant === 'danger' ? 'var(--text-danger)' : 'inherit')};

  &:hover {
    background: ${({ variant }) =>
      variant === 'danger' ? 'var(--bg-danger)' : 'var(--bg-danger-light-100)'};
    color: ${({ variant }) =>
      variant === 'danger' ? 'var(--color-white)' : 'var(--color-danger-100)'};

    .buttonIcon path {
      fill: ${({ variant }) => (variant === 'danger' ? 'var(--color-white)' : 'inherit')};
    }
  }

  &:focus-visible {
    outline: 0;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #fff,
      0px 0px 0px 4px #de5f5f;
  }

  .buttonIcon {
    order: 1;
  }
`;

export const StyledContainer = styled.div`
  background-color: var(--bg-danger-light-200);
  border-radius: var(--border-radius-md);
  color: var(--color-danger);
  padding: 16px;
  margin-top: 20px;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const StyledErrorText = styled.div`
  line-height: 20px;
  color: var(--color-danger-100);
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
