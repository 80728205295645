import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { getorderByGuid } from 'redux/modules/orderDetails/actions';
import { toggleNotificationDropdown } from 'redux/modules/notifications/actions';
import { Strings } from 'constants/Strings';
import { formatDateForDisplay } from 'utils/dateFormats';
import { getRawToken } from 'utils/validations';
import {
  handleOrderDetailModal,
  getOrderNotificationDetails,
} from 'redux/modules/orderDetails/actions';
import { ButtonCustom } from 'components/form-input/Button';
import { useHistory } from 'react-router-dom';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import Tag from 'components/tag/Tag';

function Notification({ notificationObj, onSuccessViewResult }) {
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const history = useHistory();

  var newUuid = notificationObj?.data?.case_uuid;
  if (newUuid && newUuid !== 'Sync Failed') {
    newUuid = notificationObj?.data?.case_uuid.slice(0, 8);
  }

  const handleSuccessViewResult = async (guid) => {
    dispatch(toggleNotificationDropdown(false));
    const token = await getIdTokenClaims();

    dispatch(
      getOrderNotificationDetails({
        token: getRawToken(token),
        guid: guid,
      }),
    );

    dispatch(handleOrderDetailModal(true));
  };

  const handleTryAgain = (guid) => {
    dispatch(toggleNotificationDropdown(false));
    history.push(`${urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE}/reorder/${guid}`);
  };

  return (
    <div className="d-flex gap-16 flex-column mb-3">
      <div className="d-flex flex-wrap gap-10 align-items-center">
        {notificationObj?.level == 'info' ? (
          <div className="img notification-success"></div>
        ) : notificationObj?.level == 'error' ? (
          <div className="img notification-failure"></div>
        ) : null}

        {notificationObj?.level == 'info' ? (
          <div className="txt size-16 w-700 lh-17 success">{Strings.success}</div>
        ) : notificationObj?.level == 'error' ? (
          <div className="txt size-16 w-700 lh-17 error">{Strings.unsuccessful}</div>
        ) : null}
        {notificationObj?.data?.dataset_name && (
          <Tag tagName={notificationObj?.data?.dataset_name} type="datasetName" />
        )}
      </div>
      <div className="d-flex gap-12 flex-column">
        <div className="txt size-14 lh-20">
          {notificationObj?.data?.clinical_questions}{' '}
          {notificationObj?.level == 'info' ? (
            <ButtonCustom
              cssClass="bg-white border-none space-no-wrap cursor-pointer px-0"
              onClick={() => handleSuccessViewResult(notificationObj?.data?.order_guid)}
              buttonAction={'Notification View result button clicked'}
              id="notification-view-result-button"
            >
              <span className="bg-white border-none txt success size-14  underline space-no-wrap">
                View result.
              </span>
            </ButtonCustom>
          ) : notificationObj?.level == 'error' ? (
            <ButtonCustom
              cssClass="bg-white border-none space-no-wrap cursor-pointer px-0"
              onClick={() => handleTryAgain(notificationObj?.data?.order_guid)}
              buttonAction={'Notification Try again button clicked'}
              id="notification-try-again-button"
            >
              <span className="bg-white border-none txt success size-14  underline space-no-wrap">
                Try again?
              </span>
            </ButtonCustom>
          ) : null}
        </div>
        <div className="d-flex gap-12">
          <div className="txt size-10 lh-20 tertiary">
            Completed:{' '}
            {notificationObj?.data?.order_completed_date
              ? formatDateForDisplay(notificationObj?.data?.order_completed_date)
              : '-'}
          </div>
          <div className="txt size-10 lh-20 tertiary">
            ID:{' '}
            {newUuid !== 'Sync Failed'
              ? newUuid.replace(/.{4}(?!$)/g, '$&-')
              : notificationObj?.data?.case_uuid}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
