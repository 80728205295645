import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  modifyPrognostogramOrder,
  orderPrognostogram,
  clearOrderFormData,
  resetOrderIndexData,
} from 'redux/modules/orderDetails/actions';
import { useOrdersPagedata } from '../../../pages/order/OrderPageContext';
import { INPUT_SIZE } from 'constants/Constants';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import FormButtons from '../../../pages/order/FormButtons';
import Tooltip from 'components/tooltip/Tooltip';
import { PICOT_QUESTION_LINK } from 'constants/Constants';
import { ImInfo } from 'react-icons/im';
import { InputText } from 'components/form/input';
import { labels } from 'constants/labels';

function GreenButtonPicotOrderForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isUploadingDocument, singleOrderData, phenotypeResponse, PICOGuid } =
    useSelector(({ orderDetails }) => orderDetails);
  const {
    isFormValid,
    orderPayloadData,
    dispatchOrderPageAction,
    isReorder,
    ordersPageData: { PICOOrderDetails, selectedDataSourceOption },
    token,
    isUserInViewAsMode,
    isPICOOrPhenotypeStepFailed,
  } = useOrdersPagedata();

  const {
    clinicalQuestion,
    initialClinicalQuestions,
    population,
    intervention,
    control,
    outcome,
    timeframe,
  } = PICOOrderDetails;

  const isPhenotypeAndStepFail =
    phenotypeResponse?.phenotypes &&
    Object.keys(phenotypeResponse?.phenotypes).length > 0 &&
    isPICOOrPhenotypeStepFailed;

  const onInputChange = (e, name) => {
    if (e.target.value.length <= INPUT_SIZE) {
      const data = {
        ...PICOOrderDetails,
        [name]: e.target.value,
      };

      dispatchOrderPageAction({
        type: 'order/PICOOrderDetails',
        payload: data,
      });
    } else {
      e.preventDefault();
    }
  };

  const submitOrder = () => {
    if (isFormValid()) {
      dispatch(resetOrderIndexData());
      dispatchOrderPageAction({
        type: 'order/seIsOrderSubmitted',
        payload: true,
      });

      const order = {
        ...orderPayloadData,
        order: {
          ...orderPayloadData?.order,
          initial_clinical_questions: !initialClinicalQuestions
            ? clinicalQuestion
            : initialClinicalQuestions,
          clinical_questions: clinicalQuestion,
          question_title: clinicalQuestion,
          pico_guid: '',
          user_phenotypes: '',
          product_case_type: 'PG',
          population,
          intervention,
          control,
          outcome,
          timeframe,
          data_source: selectedDataSourceOption,
          picot_status: true,
          ...(isPhenotypeAndStepFail && {
            pg_workflow_type: 'chatRwdToGB',
            user_phenotypes: phenotypeResponse?.phenotypes,
            pico_guid: PICOGuid,
          }),
        },
      };

      if (isReorder) {
        dispatch(
          modifyPrognostogramOrder({
            params: order,
            orderGuid: singleOrderData?.guid,
            token: token || '',
            isChatrwd: false,
            navigateToDashboard: navigateToDashboard,
          }),
        );
      } else {
        dispatch(
          orderPrognostogram({
            params: order,
            csrfToken: Cookies.get('csrftoken'),
            token: token || '',
            isChatrwd: false,
            navigateToDashboard: navigateToDashboard,
          }),
        );
      }
    }
  };

  const navigateToDashboard = () => {
    dispatchOrderPageAction({
      type: 'order/seIsOrderSubmitted',
      payload: false,
    });
    dispatch(clearOrderFormData());
    history.push(urlConstantsEnum.ORDER_SUBMITTED);
  };

  function PICOInfoTooltip() {
    return (
      <Tooltip
        tooltipIcon={
          <ImInfo style={{ marginTop: '8px', marginBottom: '-2px' }} fontSize={16} />
        }
        name="PICOT"
      >
        <span className="font-bold">P</span>opulation,{' '}
        <span className="font-bold">I</span>ntervention,{' '}
        <span className="font-bold">C</span>ontrol,
        <span className="font-bold">O</span>utcome, <span className="font-bold">T</span>
        imeframe
        <div className="mt-2" id={'picot-tooltip-option'}>
          For more information on how to ask a well structured PICOT question,{' '}
          <a
            style={{ color: 'var(--text-white)' }}
            href={PICOT_QUESTION_LINK}
            target="_blank"
            rel="noreferrer"
            id={'picot-question-link'}
          >
            click here
          </a>
        </div>
      </Tooltip>
    );
  }

  function renderFreeTextLink() {
    return (
      <span
        className="text-underline cursor-pointer"
        onClick={() =>
          dispatchOrderPageAction({
            type: 'order/setGreenButtonQuestionSelection',
            payload: 0,
          })
        }
      >
        {labels.freeText}.
      </span>
    );
  }

  function renderClinicalTeamNotification() {
    return (
      <div className="txt size-16 text-black-opacity-87 lh-24">
        Unfortunately, ChatRWD isn’t suited to answer your question. We recommend
        submitting your question to the clinical team using Green Button. Any clinical
        concepts generated during your ChatRWD flow will also be sent to the clinical
        team. Please confirm the study details using the PICOT {PICOInfoTooltip()}{' '}
        structure below or enter {renderFreeTextLink()}
      </div>
    );
  }

  function renderTextInfo() {
    return (
      <div className="txt size-16 text-black-opacity-87 lh-24">
        To submit your study to the clinical team, please enter study details using the
        PICOT {PICOInfoTooltip()} structure or as {renderFreeTextLink()}
      </div>
    );
  }

  return (
    <>
      <div className="picot-container p-5">
        <div className="picot-box-container m-3">
          <div className="mb-3 mt-1">
            {isPICOOrPhenotypeStepFailed
              ? renderClinicalTeamNotification()
              : renderTextInfo()}
          </div>
          <form>
            <label
              className="txt w-700 size-14 dark text-capitalize mb-2 mt-4"
              htmlFor="clinicalQuestion"
            >
              Question title <div className="color-error error-text ml-1">*</div>
            </label>
            <InputText
              value={clinicalQuestion}
              onChange={(e) => onInputChange(e, 'clinicalQuestion')}
              id="clinicalQuestion"
              background="var(--bg-white)"
            />
            <label
              className="txt w-700 size-14 dark text-capitalize mb-2 mt-4"
              htmlFor="population"
            >
              population
            </label>
            <InputText
              value={population}
              onChange={(e) => onInputChange(e, 'population')}
              id="population"
              background="var(--bg-white)"
            />
            <label
              className="txt w-700 size-14 dark text-capitalize mb-2 mt-4"
              htmlFor="intervention"
            >
              intervention
            </label>
            <InputText
              value={intervention}
              onChange={(e) => onInputChange(e, 'intervention')}
              id="intervention"
              background="var(--bg-white)"
            />
            <label
              className="txt w-700 size-14 dark text-capitalize mb-2 mt-4"
              htmlFor="control"
            >
              control
            </label>
            <InputText
              value={control}
              onChange={(e) => onInputChange(e, 'control')}
              id="control"
              background="var(--bg-white)"
            />
            <label
              className="txt w-700 size-14 dark text-capitalize mb-2 mt-4"
              htmlFor="outcome"
            >
              outcome
            </label>
            <InputText
              value={outcome}
              onChange={(e) => onInputChange(e, 'outcome')}
              id="outcome"
              background="var(--bg-white)"
            />
            <label
              className="txt w-700 size-14 dark text-capitalize mb-2 mt-4"
              htmlFor="timeframe"
            >
              timeframe
            </label>
            <InputText
              value={timeframe}
              onChange={(e) => onInputChange(e, 'timeframe')}
              id="timeframe"
              background="var(--bg-white)"
            />
          </form>
        </div>
      </div>
      <FormButtons
        disabled={!clinicalQuestion || isUploadingDocument || isUserInViewAsMode}
        onSubmit={submitOrder}
        buttonAction="Submit order button clicked, order type PG"
        actionLabel="New PG order is submitted"
        id="btn-submit-order-button"
      />
    </>
  );
}

export default GreenButtonPicotOrderForm;
