export const INPUT_SIZE = 3000;
export const QUESTION_TEXT_SIZE = 300;
export const ORDER_PAGINATION_SIZE = 20;
export const VIDEO_ID = 488177041;
export const COUNTRY_CODE_NAMESPACE = 'http://atroposhealth.com/country_code';
export const CALENDY_LINK = 'https://calendly.com/atropos-consult/15-minutes';
export const PICOT_QUESTION_LINK =
  'https://f.hubspotusercontent20.net/hubfs/19654528/Writing%20a%20PICOT%20Question.pdf';
export const USER_IDEAL_TIME = 14300;
export const SESSION_WARNING_MODAL_DISPLAY_TIME = 300;
export const ERROR_REDIRECT_DASHBOARD = 'ERROR_REDIRECT_DASHBOARD';
