import React from 'react';
import { useOrdersPagedata } from '../OrderPageContext';
import RadioButton from 'components/form-input/RadioButton';
import Tooltip from 'components/tooltip/Tooltip';
import { ImInfo } from 'react-icons/im';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useMixpanel } from 'react-mixpanel-browser';
import { labels } from 'constants/labels';

function Header() {
  const location = useLocation();
  const { pathname } = location;
  const mixpanel = useMixpanel();
  const orderTypePath = pathname?.split('/')[2];
  const { usersConfig } = useSelector(({ userProfile }) => userProfile);
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const { ordersPageData, dispatchOrderPageAction } = useOrdersPagedata();
  const { questionTypeSelection } = ordersPageData;
  const isChatRWDWorkflowDisabled =
    !usersConfig?.configuration?.prognostogram?.ask_pico_with_ai ||
    orderTypePath === 'edit';

  const onClickOfQuestionRequestTypeSlection = (index, value) => {
    mixpanel.track(`Order type ${value} selected`, {
      selection_Value: value,
    });
    dispatchOrderPageAction({ type: 'order/setQuestionTypeSelection', payload: index });
  };

  return (
    <div className="d-flex flex-column flex-lg-row flex-wrap gap-10 flex-justify-between mb-4 position-relative">
      <div className="d-md-flex position-relative">
        <span className="txt size-20 lh-32 text-black-opacity-87 w-700">
          {labels.enterYourQuestion}
        </span>
      </div>
      <div className="d-flex flex-column flex-md-row gap-10">
        <div className="order-radio-btn">
          <RadioButton
            buttonName={'Green Button'}
            onClick={(e) => onClickOfQuestionRequestTypeSlection(1, 'Green Button')}
            name="questionTypeSelection"
            value={1}
            checked={questionTypeSelection === 1 ? true : false}
            id={'btn-free-text'}
            classNameValue={`txt size-12 w-700 font-inter lh-16 ${
              questionTypeSelection === 1 ? 'radio-active ' : ''
            } `}
          />
        </div>

        <div className="order-radio-btn">
          <div
            className={`${
              !usersConfig?.configuration?.prognostogram?.ask_pico_with_ai
                ? 'radio-btn-disabled'
                : ''
            }`}
          >
            <div className="d-flex align-items-center gap-7 justify-content-between">
              <RadioButton
                buttonName="ChatRWD"
                onClick={(e) => onClickOfQuestionRequestTypeSlection(3, 'ChatRWD')}
                name="questionTypeSelection"
                value={3}
                disabled={isChatRWDWorkflowDisabled}
                checked={questionTypeSelection === 3 ? true : false}
                id="btn-ask-with-ai"
                classNameValue={`txt size-12 w-700 font-inter lh-16 ${
                  questionTypeSelection === 3 ? 'radio-active ' : ''
                } ${isChatRWDWorkflowDisabled ? 'disabled' : ''}`}
              />
              <Tooltip
                tooltipIcon={
                  <ImInfo
                    style={{ color: questionTypeSelection === 3 ? '#016f66' : '' }}
                    className="tooltip-button"
                    fontSize={16}
                  />
                }
                cssClassName="d-flex"
                name="AI"
              >
                <div className="mt-0" id="ai-tooltip-option">
                  This feature is only available to certain users. For more information
                  contact us at{' '}
                  <a
                    className="text-white"
                    href={'mailto:support@atroposhealth.com'}
                    id={'AI-question-link'}
                  >
                    support@atroposhealth.com.
                  </a>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
