import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styles from './phenotype.module.scss';
import PhenotypeTable from './PhenotypeTable';
import { labels } from 'constants/labels';
import { useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';

function Phenotype({ data }) {
  const mixpanel = useMixpanel();

  const { phenotypeValidationsErrors } = useSelector(({ orderDetails }) => orderDetails);
  function hasUnableToReturnPhenotype(studyDetail) {
    return studyDetail?.entities?.some((entity) => {
      if (!Array.isArray(entity?.phenotypes) || entity?.phenotypes.length === 0) {
        return true;
      }
  
      return entity?.phenotypes?.some(
        (phenotype) =>
          phenotype?.tql === null || phenotype?.tql.toLowerCase() === 'nan' ||
          phenotype?.code_set?.length === 0 ||
          phenotype?.code_set?.some(
            (codeSet) =>
              codeSet?.concept_description?.toLowerCase() === labels.unableToReturnPhenotypeDescription
          )
      );
    });
  }

  function handleSelect(index) {
    const tabNames = Object.keys(data);
    const selectedTabName = tabNames[index];

    mixpanel.track(`Phenotype Table: ${selectedTabName} Tab Viewed`, {
      'Tab Name': selectedTabName,
      'Tab Index': index,
    });
  }

  return (
    <Tabs onSelect={handleSelect}>
      <TabList className={styles.customTabList}>
        {data &&
          Object.keys(data).map((key) => {
            const item = data[key];
            const isError = hasUnableToReturnPhenotype(item);
            const isValidationError =
              phenotypeValidationsErrors &&
              phenotypeValidationsErrors[key] &&
              phenotypeValidationsErrors[key].length
                ? true
                : false;

            const classNames = `text-capitalize ${
              isError || isValidationError ? 'color-error' : ''
            }`;

            return (
              <Tab key={`tab-${key}`}>
                <span className={classNames}>{key}</span>
              </Tab>
            );
          })}
      </TabList>

      {data &&
        Object.keys(data).map(
          (key, index) =>
            Object.keys(data[key]).length > 0 && (
              <TabPanel key={`tab-${key}-body-${index}`}>
                <PhenotypeTable data={data} phenotypes={key} />
              </TabPanel>
            ),
        )}
    </Tabs>
  );
}

export default Phenotype;
