import React from 'react';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { ReactComponent as Refresh } from 'assets/images/Refresh.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { Button } from 'components/button';
import { labels } from 'constants/labels';

function StudyDetailsSummary({ studyFormatObject, index, onClick, children, ...props }) {
  const {
    handleButtonAction,
    ordersPageData: { chatRWD },
    renderIconTitle,
  } = useOrdersPagedata();

  const { studyFormats, isOpenAiLoading } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const { isPICOTFinalized } = chatRWD;

  const isNotOnCurrentFrame =
    studyFormats?.length - 1 > props?.optionIndex ||
    isOpenAiLoading ||
    isPICOTFinalized ||
    false;

  function renderIcon(icon) {
    switch (icon) {
      case aiButtonsEnum.REGENERATE_SUGGESTIONS:
        return (
          <Refresh className="cursor-pointer" onClick={() => handleIconClick(icon)} />
        );
      case aiButtonsEnum.MANUALLY_EDIT:
        return (
          <EditIcon
            className="cursor-pointer"
            onClick={() => {
              if (!isNotOnCurrentFrame) props?.onEdit(icon);
            }}
          />
        );
      case aiButtonsEnum.REGENERATE_PICOT:
        return (
          <Refresh
            className="cursor-pointer"
            onClick={() => {
              if (!isNotOnCurrentFrame) props?.onRefresh(icon);
            }}
          />
        );
      default:
    }
  }

  function handleIconClick(key) {
    if (!isNotOnCurrentFrame)
      handleButtonAction({
        key,
        index: props?.optionIndex,
      });
  }

  function renderButtons() {
    return studyFormatObject?.inline_buttons &&
      studyFormatObject?.inline_buttons?.length > 0 ? (
      <div className="d-flex align-items-center w-100 gap-20">
        {studyFormatObject?.display_text_2 && (
          <div className="txt size-16 lh-24 conclusion-text text-gray-700">
            {studyFormatObject.display_text_2}
          </div>
        )}
        <div className="d-flex gap-5 justify-content-end ml-auto">
          {!isNotOnCurrentFrame &&
            studyFormatObject?.inline_buttons?.map((icon) => {
              if (icon === aiButtonsEnum.REGENERATE_PICOT && props.optionIndex !== 0) {
                return null;
              }

              return (
                <Button
                  icon={renderIcon(icon)}
                  title={renderIconTitle({ icon })}
                  key={icon}
                  text
                  buttonaction={`${renderIconTitle({ icon })} button clicked`}
                />
              );
            })}
        </div>
      </div>
    ) : (
      studyFormatObject?.display_text_2 && (
        <div className="txt size-16 lh-28 conclusion-text text-gray-700">
          {studyFormatObject.display_text_2}
        </div>
      )
    );
  }

  if (
    studyFormatObject?.has_user_generated_study_details_with_ai &&
    studyFormatObject?.study_format &&
    (studyFormatObject?.study_design ||
      studyFormatObject?.reason ||
      studyFormatObject?.changes)
  ) {
    return (
      <div className="d-flex flex-column gap-20">
        <div className="txt gray-20 size-18 w-700 lh-24 text-capitalize">
          {labels?.studyDesign}
        </div>
        <div className="d-flex flex-column gap-20">
          <div className="d-flex flex-column gap-30">
            <div>{children}</div>
            <div className="txt gray-20 size-18 w-700 lh-24 text-capitalize">
              {labels?.explanation}
            </div>
          </div>

          <div className="d-flex flex-column gap-30">
            <div>
              <div className="txt text-black-opacity-87 size-16 w-700 lh-24 text-capitalize">
                <span data-testid="study-design">{studyFormatObject?.study_design}</span>{' '}
                {labels?.study}:{' '}
                <span
                  className="txt text-black-opacity-87 size-16 lh-24 text-capitalize-none"
                  data-testid="study-reason"
                >
                  {' '}
                  {studyFormatObject?.reason}
                </span>
              </div>
            </div>
            {studyFormatObject?.changes && (
              <div>
                <div className="txt text-black-opacity-87 size-16 w-700 lh-24">
                  {labels?.studyAdjustments}:{' '}
                  <span
                    className="txt text-black-opacity-87 size-16 lh-24 text-capitalize-none"
                    data-testid="study-adjustment-text"
                  >
                    {' '}
                    {studyFormatObject?.changes}
                  </span>
                </div>
              </div>
            )}

            {renderButtons()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-80">
      {studyFormatObject?.reason !== '' && studyFormatObject?.reason !== undefined ? (
        <div className="mb-3 txt size-16 gray-20 lh-28 intro-text">
          {studyFormatObject?.reason}
        </div>
      ) : (
        <>
          {studyFormatObject?.display_text_1 && (
            <div
              className="mb-3 txt size-16 gray-20 lh-28 intro-text"
              dangerouslySetInnerHTML={{
                __html: studyFormatObject?.display_text_1,
              }}
            />
          )}
        </>
      )}

      {children}

      {renderButtons()}
    </div>
  );
}

export default StudyDetailsSummary;
