import styled from 'styled-components';

const StyledStatusButton = styled.button`
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  text-transform: uppercase;
  width: auto;
  max-width: 300px;
  padding: 12px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  gap: 10px;
  align-items: center;
  line-height: 24px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  white-space: nowrap;

  color: ${({ severity }) => {
    switch (severity) {
      case 'green':
        return 'var(--color-primary)';
      case 'yellow':
        return 'var(--color-warning)';
      case 'red':
        return 'var(--color-danger)';
      default:
        return 'var(--color-primary)';
    }
  }};

  background: ${({ severity }) => {
    switch (severity) {
      case 'green':
        return 'var(--bg-light-mint)';
      case 'yellow':
        return 'var(--bg-warning-primary)';
      case 'red':
        return 'var(--bg-danger-light)';
      default:
        return 'var(--bg-light-mint)';
    }
  }};

  border-color: ${({ severity }) => {
    switch (severity) {
      case 'green':
        return 'var(--border-teal-dark)';
      case 'yellow':
        return 'var(--border-warning)';
      case 'red':
        return 'var(--border-danger-light)';
      default:
        return 'var(--border-teal-dark)';
    }
  }};

  padding: ${({ size }) => {
    switch (size) {
      case 'large':
        return '10px 20px';
      case 'small':
        return '5px';
      default:
        return '10px 20px';
    }
  }};
`;

export default StyledStatusButton;
