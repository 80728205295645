import React from 'react';
import PICphenotypelayout from './PICphenotypelayout';
import OutcomePhenotypelayout from './OutcomePhenotypelayout';
import { useDispatch } from 'react-redux';
import { setPhenotypeData } from 'redux/modules/orderDetails/actions';

function PhenotypeTable({ data, phenotypes }) {
  const dispatch = useDispatch();

  function handleDeleteRow({ index, entityIndex, code, phenotype }) {
    const phenotypeData = structuredClone(data);

    const curPhenotypeCodesets =
      phenotypeData[phenotypes]?.entities[entityIndex].phenotypes[0]?.code_set;

    const filteredCodeSet =
      curPhenotypeCodesets &&
      curPhenotypeCodesets.length > 0 &&
      curPhenotypeCodesets.filter(
        (codeset, codesetIndex) =>
          codeset.concept_code !== code.concept_code && codesetIndex !== index,
      );

    if (filteredCodeSet) {
      phenotypeData[phenotypes].entities[entityIndex].phenotypes[0].code_set =
        filteredCodeSet;

      dispatch(setPhenotypeData({ phenotypeData, phenotype }));
    }
  }

  return (
    <>
      {Object.keys(data[phenotypes])?.length > 0 && (
        phenotypes !== 'outcomes' && phenotypes !== 'outcome' ? (
          <PICphenotypelayout
            phenotype={phenotypes}
            data={data}
            onDelete={handleDeleteRow}
          />
        ) : (
          <OutcomePhenotypelayout
            phenotype={phenotypes}
            data={data}
            onDelete={handleDeleteRow}
          />
        )
      )}
    </>
  );
}

export default PhenotypeTable;
