import React from 'react';
import { labels } from 'constants/labels';
import AnswerStatusIndicator from './AnswerStatusIndicator';
import { useSelector } from 'react-redux';
import { SummaryLoaderLayout } from './AIOrderLoaderLayout';
import SummaryHeader from './SummaryHeader';
import Message from '../../components/Message';

function Answer({ summary }) {
  const { aiSearchState = {} } = useSelector(({ searchOrder }) => searchOrder);
  const { aiSearchLoading = false } = aiSearchState;

  if (aiSearchLoading)
    return (
      <SummaryLoaderLayout title={<SummaryHeader header={labels.atroposSummary} />} />
    );

  return (
    <div className="d-flex flex-column gap-15">
      <div className="d-flex flex-wrap gap-5 justify-content-between align-items-center">
        <SummaryHeader header={labels.atroposSummary} />
        <AnswerStatusIndicator button={summary?.answer_button} />
      </div>

      {summary?.answer ? (
        <div className="txt size-16 w-400 lh-21 text-slate-gray">{summary?.answer}</div>
      ) : (
        <Message severity="info" text={labels.noSummaryAvailable} />
      )}
    </div>
  );
}

export default Answer;
