import React, { createContext, useContext, useReducer, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCheckCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import {
  resetStudyFormats,
  fetchResultThroughOpenAi,
  fetchPicoToPhenotype,
  savePicoOptions,
  clearStudyFormats,
  updateStudyDetails,
  resetPhenotypeData,
  fetchSuggestionsThroughOpenAi,
  incorporateSuggestionsThroughOpenAi,
} from 'redux/modules/orderDetails/actions';
import { BiUndo } from 'react-icons/bi';
import { FaMagic, FaRegEdit } from 'react-icons/fa';
import { useToken } from 'hooks/useToken';
import { useOrderPath } from 'hooks/useOrderPath';
import { Strings } from 'constants/Strings';
import orderFormErrorStr from 'constants/errorStrings';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { dateFormatyyyymmdd } from 'utils/dateFormats';
import { isValidDate } from 'utils/dateFormats';
import { validateEmail } from 'utils/validations';
import { constructQuestionFromStudyFormat } from 'utils/questionTextModifier';
import { ReactComponent as Flash } from 'assets/images/Flash.svg';
import { ReactComponent as CheckGray } from 'assets/images/CheckGray.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { labels } from 'constants/labels';
import { useSessionStorageState } from 'hooks/useSessionStorageState';

const OrderPageContext = createContext();

const orderPageInitialState = {
  requestOnBehalf: false,
  ordered_for: {
    firstName: '',
    lastName: '',
    email: '',
  },
  selectedProviderMotivation: null,
  patientInfo: {
    patientLastName: '',
    patientBirthDate: '',
  },
  selectedDataSourceOption: [],
  isConfidential: false,
  urgencyType: 'Not Urgent',
  questionTypeSelection: 1,
  greenButtonQuestionSelection: 0,
  errorsObject: {},
  isConfirmationModalOpen: false,
  isOrderSubmitted: false,
  picotSwitch: {
    value: false,
  },
  sharingValue: 'Library',
  loadingTitle: Strings.generatingPicotMessage,
  PICOOrderDetails: {
    questionTitle: '',
    studyFormat: '',
    clinicalQuestion: '',
    initialClinicalQuestions: '',
    population: '',
    intervention: '',
    control: '',
    outcome: '',
    timeframe: '',
  },
  chatRWD: {
    isManualInputMode: false,
    isManualEnterFromPhenotype: false,
    hasWorkflowStarted: false,
    hasUserSelectedManualWorkflow: false,
    questionTitle: '',
    studyFormat: '',
    clinicalQuestion: '',
    initialClinicalQuestions: '',
    formValues: {},
    isClinicalRetry: false,
    selectedSuggestionsOptions: [],
    checkedSuggestionsOptions: {},
    hasClickedOnStartOver: false,
    userPhenotypes: {},
    userAction: {
      action: undefined,
      options: undefined,
    },
    isPICOTFinalized: false,
    isPhenotypeFinalized: false,
    selectedDataSourceOption: [],
    selectedProviderMotivation: null,
    errors: {},
    generalStudyParameters: {
      study_years_min: 2015,
      study_years_max: new Date().getFullYear(),
      min_history_value: 90,
      min_history_type: 'days',
      min_followup_value: 0,
      min_followup_type: 'years',
      downsample: 'downsample',
      downsample_number: 100000,
    },
    selectedStudyFormat: labels.comparativeEffectiveness,
    visibleFields: ['population', 'cohort_1', 'cohort_2', 'outcomes'],
    nextFieldIndex: 3,
    isStudyParamFinlized: false,
    hasMotivationSelectChanged: false,
    hasDataSetConfirmed: false,
  },
};

function ordersReducer(state, action) {
  switch (action.type) {
    case 'order/toggleRequestOnBehalf':
      return {
        ...state,
        requestOnBehalf: action.payload,
      };
    case 'order/updateOrderedFor':
      return {
        ...state,
        ordered_for: {
          ...state.ordered_for,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'order/setOrderedFor':
      return {
        ...state,
        ordered_for: {
          ...state.ordered_for,
          ...action.payload,
        },
      };
    case 'order/clearOrderedFor':
      return {
        ...state,
        ordered_for: {
          firstName: '',
          lastName: '',
          email: '',
        },
      };
    case 'order/setSelectedProviderMotivation':
      return {
        ...state,
        selectedProviderMotivation: action.payload,
      };
    case 'order/patientInfo':
      return {
        ...state,
        patientInfo: {
          ...state.patientInfo,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'order/setselectedDataSourceOption':
      return {
        ...state,
        selectedDataSourceOption: action.payload,
      };
    case 'order/setConfidential':
      return {
        ...state,
        isConfidential: action.payload,
      };
    case 'order/setUrgencyType':
      return {
        ...state,
        urgencyType: action.payload,
      };
    case 'order/setQuestionTypeSelection':
      return {
        ...state,
        questionTypeSelection: action.payload,
      };
    case 'order/setGreenButtonQuestionSelection':
      return {
        ...state,
        greenButtonQuestionSelection: action.payload,
      };
    case 'order/setFormErrorsObject':
      return {
        ...state,
        errorsObject: {
          ...state.errorsObject,
          ...action.payload,
        },
      };
    case 'order/setFormErrorByKey':
      return {
        ...state,
        errorsObject: {
          ...state.errorsObject,
          [action.payload.key]: action.payload.error,
        },
      };
    case 'order/setFormErrorText':
      return {
        ...state,
        errorText: action.payload,
      };
    case 'order/setNavigationConfirmationModalOpen':
      return {
        ...state,
        isConfirmationModalOpen: action.payload,
      };
    case 'order/seIsOrderSubmitted':
      return {
        ...state,
        isOrderSubmitted: action.payload,
      };
    case 'order/PICOOrderDetails':
      return {
        ...state,
        PICOOrderDetails: {
          ...state.PICOOrderDetails,
          ...action.payload,
        },
      };
    case 'order/updatePICOOrderDetailsField':
      return {
        ...state,
        PICOOrderDetails: {
          ...state.PICOOrderDetails,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'order/setChatRWDData':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          ...action.payload,
        },
      };
    case 'order/updateChatRWDField':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'order/updateChatRWDFormValuesByField':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          formValues: {
            ...state.chatRWD.formValues,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case 'order/updateChatRWDFormValues':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          formValues: {
            ...state.chatRWD.formValues,
            ...action.payload,
          },
        },
      };
    case 'order/clearChatRWDFormValues':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          formValues: {},
        },
      };
    case 'order/setLoadingTitle':
      return {
        ...state,
        loadingTitle: action.payload,
      };
    case 'order/setSuggestionsOptions':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          selectedSuggestionsOptions: [
            ...state.chatRWD.selectedSuggestionsOptions,
            action.payload,
          ],
        },
      };

    case 'order/setUserAction':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          userAction: {
            action: action.payload.userAction,
            options: action.payload.options,
          },
        },
      };
    case 'order/setChatRWDErrorsObject':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          errors: action.payload,
        },
      };
    case 'order/setChatRWDErrorByKey':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          errors: { ...state.chatRWD.errors, [action.payload.key]: action.payload.error },
        },
      };
    case 'order/resetChatRWDData':
      return {
        ...state,
        chatRWD: {
          ...orderPageInitialState.chatRWD,
          questionTitle: state.chatRWD.questionTitle,
        },
      };
    case 'order/updateGeneralStudyParametersValues':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          generalStudyParameters: {
            ...state.chatRWD.generalStudyParameters,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
}

function motivationRequiresPatientData(providerMotivation) {
  return providerMotivation && providerMotivation === 'Patient Care';
}

function hasUnableToReturnPhenotype(data) {
  for (const key in data) {
    const allEntitiesFailedByDescription = data[key]?.entities?.every((entity) =>
      entity?.phenotypes?.every(
        (phenotype) =>
          !phenotype?.code_set?.length ||
          phenotype?.code_set?.some(
            (codeSet) =>
              codeSet?.concept_description?.toLowerCase() === labels.unableToReturnPhenotypeDescription,
          ),
      ),
    );
    const allEntitiesFailedByTql = data[key]?.entities?.every((entity) =>
      entity?.phenotypes?.every(
        (phenotype) => phenotype?.tql === null || phenotype?.tql?.toLowerCase() === 'nan',
      ),
    );
    if (allEntitiesFailedByDescription || allEntitiesFailedByTql) {
      return true;
    }
  }
  return false;
}

function OrderPageProvider({ children }) {
  const [viewAsSession, setViewAsSession] = useSessionStorageState({}, 'viewAsSession');
  const token = useToken();
  const params = useParams();
  const dispatch = useDispatch();
  const orderTypePath = useOrderPath();
  const isUserInViewAsMode = Boolean(viewAsSession && viewAsSession?.user?.email);
  const location = useLocation();
  const { selectedInteractionType, aiSummaryQuestion } = location?.state || {};

  const { datasetList } = useSelector(({ userSubscription }) => userSubscription);
  const {
    singleOrderData,
    motivations,
    uploadedFilesList,
    aIResponseErrors,
    studyFormats,
    PICOGuid,
    studyFormat: currentStudyFormat,
    phenotypeResponse,
    orderTaskStatusError,
    orderPrognostogramError,
    study_design,
    isDataSetsLoading,
  } = useSelector(({ orderDetails }) => orderDetails);
  const { userData, usersConfig } = useSelector(({ userProfile }) => userProfile);

  const [ordersPageData, dispatchOrderPageAction] = useReducer(
    ordersReducer,
    orderPageInitialState,
  );

  const isReorder =
    singleOrderData?.guid &&
    singleOrderData?.status === 'Pending' &&
    orderTypePath === 'edit';

  const hasZeroOutcomeMaxValue = phenotypeResponse?.phenotypes?.outcomes
    ? phenotypeResponse.phenotypes.outcomes.entities?.some(
        (entity) => entity.outcome_max_value === 0 || entity.outcome_max_value === null,
      )
    : false;

  const isFreeOrderLimitReached =
    usersConfig?.configuration?.chatrwd_count === 0 || false;

  const isUnableToReturnPhenotype = hasUnableToReturnPhenotype(
    phenotypeResponse?.phenotypes,
  );

  useEffect(() => {
    if (
      (studyFormats && studyFormats.length > 0) ||
      (aIResponseErrors && Object.keys(aIResponseErrors).length > 0) ||
      phenotypeResponse ||
      PICOGuid ||
      orderTaskStatusError ||
      orderPrognostogramError
    ) {
      handleReset();
    }
  }, []);

  useEffect(() => {
    if (
      singleOrderData &&
      singleOrderData.product_case_type &&
      usersConfig?.configuration
    ) {
      const payload =
        usersConfig?.configuration?.prognostogram?.ask_pico_with_ai &&
        singleOrderData?.product_case_type === 'ChatRWD' &&
        !isReorder &&
        orderTypePath !== 'edit'
          ? 3
          : 1;

      dispatchOrderPageAction({
        type: 'order/setQuestionTypeSelection',
        payload,
      });
    }
  }, [singleOrderData, dispatchOrderPageAction, usersConfig?.configuration]);

  useEffect(() => {
    if (
      params &&
      params.guid &&
      singleOrderData &&
      Object.keys(singleOrderData).length > 0
    ) {
      const {
        clinical_questions,
        initial_clinical_questions,
        population,
        intervention,
        control,
        outcome,
        timeframe,
        ai_search_history,
        question_title,
        motivations,
        picot_status,
      } = singleOrderData;

      const data = {
        clinicalQuestion: clinical_questions,
        initialClinicalQuestions: initial_clinical_questions,
        questionTitle: question_title || clinical_questions,
      };
      dispatchOrderPageAction({
        type: 'order/PICOOrderDetails',
        payload: {
          ...data,
          population,
          intervention,
          control,
          outcome,
          timeframe,
        },
      });
      if (picot_status) {
        dispatchOrderPageAction({
          type: 'order/setGreenButtonQuestionSelection',
          payload: 1,
        });
      } else {
        dispatchOrderPageAction({
          type: 'order/setGreenButtonQuestionSelection',
          payload: 0,
        });
      }

      if (
        ai_search_history &&
        ai_search_history.guid &&
        ai_search_history.response_pico
      ) {
        const dataObj = {
          ...data,
          hasWorkflowStarted: true,
          isPICOTFinalized: true,
          selectedSuggestionsOptions: [{ option: 'Study Accurate' }],
        };

        if (ai_search_history?.response_pico?.study_design === labels.crossSectional) {
          handleShowCohortStudies({
            format: ai_search_history?.response_pico?.study_format,
          });
        }
        handleUpdateChatRWDData(dataObj);
      } else {
        handleUpdateChatRWDData({
          ...data,
          hasWorkflowStarted: false,
          selectedSuggestionsOptions: [],
          formValues: {
            population,
            intervention,
            control,
            outcome,
          },
        });
      }

      if (motivations && motivations.length) {
        const hasTrueValue = motivations.some((motivation) => motivation.value === true);
        if (hasTrueValue) {
          handleUpdateChatRWDField({
            field: 'hasMotivationSelectChanged',
            value: true,
          });
        }
      }
    }
  }, [params, singleOrderData, dispatchOrderPageAction, dispatchOrderPageAction]);

  useEffect(() => {
    if (aIResponseErrors && Object.keys(aIResponseErrors).length > 0) {
      const lastSelectedOptionIndex =
        ordersPageData?.chatRWD?.selectedSuggestionsOptions?.length - 1;

      if (aIResponseErrors.openAiError) {
        dispatchOrderPageAction({ type: 'order/resetChatRWDData' });
        return;
      }

      if (
        aIResponseErrors.openAiSuggestionError ||
        aIResponseErrors.openAiSuggestionIncorporationError ||
        aIResponseErrors.openAiPhenotypeError
      ) {
        if (lastSelectedOptionIndex === studyFormats.length - 1) {
          removeSelectedOptionWithIndex(lastSelectedOptionIndex);
        }
        if (ordersPageData?.chatRWD?.isPICOTFinalized) {
          handleUpdateChatRWDField({
            field: 'isPICOTFinalized',
            value: false,
          });
        }
        return;
      }

      if (aIResponseErrors.picoSavingError) {
        if (studyFormats.length === 0) {
          dispatchOrderPageAction({ type: 'order/resetChatRWDData' });
          return;
        }

        if (studyFormats.length > 0) {
          if (lastSelectedOptionIndex === studyFormats.length - 1)
            removeSelectedOptionWithIndex(lastSelectedOptionIndex);
        } else {
          dispatchOrderPageAction({ type: 'order/resetChatRWDData' });
        }
      }
      if (
        aIResponseErrors?.syncedPhenotypeError ||
        aIResponseErrors?.datasetSelectorError
      ) {
        handleUpdateChatRWDField({
          field: 'isPhenotypeFinalized',
          value: false,
        });
      }
    }
  }, [
    aIResponseErrors,
    dispatchOrderPageAction,
    ordersPageData?.chatRWD?.selectedSuggestionsOptions,
    studyFormats,
  ]);

  useEffect(() => {
    if (selectedInteractionType || aiSummaryQuestion) {
      dispatchOrderPageAction({
        type: 'order/setQuestionTypeSelection',
        payload: selectedInteractionType || 3,
      });
      handleUpdateChatRWDField({
        field: 'questionTitle',
        value: aiSummaryQuestion || '',
      });
    }
  }, []);

  useEffect(() => {
    if (isDataSetsLoading) {
      dispatchOrderPageAction({
        type: 'order/setLoadingTitle',
        payload: 'Generating Data Source Recommendations...',
      });
    }
  }, [isDataSetsLoading]);

  useEffect(() => {
    if (currentStudyFormat) {
      dispatchOrderPageAction({
        type: 'order/PICOOrderDetails',
        payload: {
          ...currentStudyFormat,
          outcome: currentStudyFormat?.outcome || currentStudyFormat?.outcomes,
        },
      });
    }
  }, [currentStudyFormat]);

  let documentPaths = [];
  if (
    uploadedFilesList &&
    uploadedFilesList?.length > 0 &&
    ordersPageData?.questionTypeSelection !== 3
  ) {
    uploadedFilesList.forEach((file) => {
      const obj = {
        key_path: file.key_path,
        phi_disclaimer: file.phi_disclaimer,
        is_uploaded: file.is_uploaded,
      };
      documentPaths.push(obj);
    });
  }

  const selectedMotivation =
    ordersPageData?.questionTypeSelection === 3
      ? ordersPageData?.chatRWD?.selectedProviderMotivation
      : ordersPageData?.selectedProviderMotivation;

  const orderPayloadData = {
    user: {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
    },
    institute: {
      institute_name: userData?.institute?.name,
      institute_guid: userData?.institute?.guid,
    },
    patient:
      motivationRequiresPatientData(ordersPageData?.selectedProviderMotivation) &&
      usersConfig?.configuration?.prognostogram?.motivations?.patient_care
        ? {
            last_name: ordersPageData?.patientInfo?.patientLastName,
            birth_date: ordersPageData?.patientInfo?.patientBirthDate
              ? dateFormatyyyymmdd(ordersPageData?.patientInfo?.patientBirthDate)
              : '',
          }
        : undefined,
    order: {
      picot_status: ordersPageData?.picotSwitch?.value,
      sharing_type: ordersPageData?.sharingValue,
      confidential: ordersPageData?.isConfidential,
      is_urgent: ordersPageData?.urgencyType === 'Urgent' ? true : false,
      documents: ordersPageData?.questionTypeSelection !== 3 ? documentPaths : [],
      phi_disclaimer: documentPaths.length ? true : null,
      motivations: motivations
        ? Object.fromEntries(
            motivations.map(({ category, value }) => [
              category,
              selectedMotivation
                ? selectedMotivation === category
                  ? true
                  : false
                : false,
            ]),
          )
        : {},
      ordered_for: ordersPageData?.requestOnBehalf
        ? {
            first_name: ordersPageData?.ordered_for?.firstName,
            last_name: ordersPageData?.ordered_for?.lastName,
            email: ordersPageData?.ordered_for?.email,
          }
        : undefined,
      previous_order_guid: params?.guid,
    },
  };

  function handleReset() {
    dispatch(resetStudyFormats());
    dispatchOrderPageAction({ type: 'order/resetChatRWDData' });
  }

  function validateRequestOnBehalfSection() {
    const { questionTypeSelection, ordered_for } = ordersPageData;
    let errorsObject = {};

    const { firstName, lastName, email } = ordered_for;

    if (!firstName) {
      errorsObject.firstName = Strings.orderFirstNameError;
    }
    if (!lastName) {
      errorsObject.lastName = Strings.orderLastNameError;
    }
    if (!validateEmail(email)) {
      errorsObject.email = orderFormErrorStr.email;
    }

    if (questionTypeSelection === 3) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorsObject',
        payload: errorsObject,
      });
    }

    return errorsObject;
  }

  function isFormValid() {
    const {
      requestOnBehalf,
      selectedProviderMotivation,
      patientInfo,
      selectedDataSourceOption,
      ordered_for,
    } = ordersPageData;

    let errorsObject = {};

    if (requestOnBehalf) {
      errorsObject = {
        ...errorsObject,
        ...validateRequestOnBehalfSection(),
      };
    }

    if (
      motivationRequiresPatientData(selectedProviderMotivation) &&
      usersConfig?.configuration?.prognostogram?.motivations?.patient_care
    ) {
      const { patientLastName, patientBirthDate } = patientInfo;
      const date = new Date(patientInfo?.patientBirthDate);
      if (!patientLastName) {
        errorsObject.patientLastName = Strings.patientLastNameError;
      }
      if (!patientBirthDate && !isValidDate(date)) {
        errorsObject.patientBirthDate = Strings.patientBirthDateError;
      }
    }
    if (!selectedProviderMotivation) {
      errorsObject.motivation = Strings.motivationError;
    }

    if (!selectedDataSourceOption?.length) {
      errorsObject.dataSourceSelectionError = Strings.dataSourceSelectionError;
    }

    dispatchOrderPageAction({
      type: 'order/setFormErrorsObject',
      payload: errorsObject,
    });

    const errorText = Object.values(errorsObject).find((value) => value);

    return !errorText;
  }

  function isChatRWDFormValid() {
    const {
      requestOnBehalf,
      chatRWD: { selectedDataSourceOption, selectedProviderMotivation },
    } = ordersPageData;

    let errorsObject = {};

    if (requestOnBehalf) {
      errorsObject = {
        ...errorsObject,
        ...validateRequestOnBehalfSection(),
      };
    }

    if (!selectedProviderMotivation) {
      errorsObject.motivation = Strings.motivationError;
    }

    if (!selectedDataSourceOption?.length) {
      errorsObject.dataSourceSelectionError = Strings.dataSourceSelectionError;
    }

    dispatchOrderPageAction({
      type: 'order/setChatRWDErrorsObject',
      payload: errorsObject,
    });

    const errorText = Object.values(errorsObject).find((value) => value);

    return !errorText;
  }

  function showButtonIcons(buttonAction) {
    switch (buttonAction) {
      case aiButtonsEnum.PICOT_ACCURATE:
      case aiButtonsEnum.PHENOTYPES_ACCURATE:
        return <CheckGray />;
      case aiButtonsEnum.SUGGEST_IMPROVEMENTS:
      case aiButtonsEnum.PHENOTYPES_IMPROVEMENTS:
        return <Flash />;
      case aiButtonsEnum.MANUALLY_EDIT:
      case aiButtonsEnum.MANUALLY_EDIT_PICO:
      case aiButtonsEnum.MANUALLY_ENTER_PICO:
      case aiButtonsEnum.MANUALLY_EDIT_QUESTION:
      case aiButtonsEnum.MANUALLY_EDIT_PICOT_ON_ERROR:
      case aiButtonsEnum.SUGGESTIONS_MANUALLY_EDIT:
        return <EditIcon />;
      case aiButtonsEnum.GENERATE_PICO:
        return <FaMagic />;
      case aiButtonsEnum.EDIT_PHENOTYPES:
        return <AiOutlineMinusCircle />;
      case aiButtonsEnum.UNDO_PHENOTYPE_EDITS:
        return <BiUndo />;
      default:
        return;
    }
  }

  function handleLoadingStrings(key) {
    const loadingMessages = {
      [aiButtonsEnum.GENERATE_PICO]: Strings.generatingPicotMessage,
      [aiButtonsEnum.KEEP_ORIGINAL]: Strings.generatingPicotMessage,
      [aiButtonsEnum.REGENERATE_PICOT]: Strings.generatingPicotMessage,
      [aiButtonsEnum.REGENERATE_SUGGESTIONS]: Strings.suggestionGeneration,
      [aiButtonsEnum.SUGGEST_IMPROVEMENTS]: Strings.suggestionGeneration,
      [aiButtonsEnum.SAVE_PICO]: Strings.savingsPicotMessage,
      [aiButtonsEnum.SAVE_PHENOTYPE_TO_PICO]: Strings.savingsPicotMessage,
      [aiButtonsEnum.PICOT_ACCURATE]: Strings.phenotypeGeneration,
      [aiButtonsEnum.REGENERATE_PHENOTYPES]: Strings.phenotypeGeneration,
      [aiButtonsEnum.INCORPORATE_CHANGES]: Strings.incorporatingSuggestionMessage,
    };
    const payload = loadingMessages[key];
    if (payload) {
      dispatchOrderPageAction({
        type: 'order/setLoadingTitle',
        payload,
      });
    }
  }

  const {
    userAction,
    selectedSuggestionsOptions,
    initialClinicalQuestions,
    questionTitle,
    isClinicalRetry,
    formValues,
    checkedSuggestionsOptions,
    selectedStudyFormat,
    hasMotivationSelectChanged,
  } = ordersPageData.chatRWD;

  function handleButtonAction({ key: action, index, options }) {
    handleLoadingStrings(action);
    switch (action) {
      case aiButtonsEnum.GENERATE_PICO:
        handleUpdateChatRWDField({
          field: 'userAction',
          value: { action, option: undefined },
        });
        handleGeneratePICOWorkflow();
        break;
      case aiButtonsEnum.REGENERATE_PICOT:
        handleUpdateChatRWDField({
          field: 'userAction',
          value: { action, option: undefined },
        });
        handleRegenerateStudyDetails();
        break;
      case aiButtonsEnum.PICOT_ACCURATE:
      case aiButtonsEnum.REGENERATE_PHENOTYPES:
        handleUpdateChatRWDField({
          field: 'isPICOTFinalized',
          value: true,
        });
        fetchPhenotype();
        break;
      case aiButtonsEnum.MANUALLY_ENTER_PICO:
        handleUpdateChatRWDField({ field: 'isManualInputMode', value: true });
        break;
      case aiButtonsEnum.MANUALLY_SAVE_PICO:
        handleManualSavePICOWorkflow();
        break;
      case aiButtonsEnum.START_OVER:
        handleUpdateChatRWDField({ field: 'hasClickedOnStartOver', value: true });
        break;
      case aiButtonsEnum.MANUALLY_EDIT_PICO:
        handleCurrentStudyFormatUpdate({ isShow: true, index: studyFormats.length - 1 });
        handleUpdateChatRWDData({
          isManualEnterFromPhenotype: true,
          isPICOTFinalized: false,
        });
        break;
      case aiButtonsEnum.CANCEL_PICO_EDIT:
        handleUpdateChatRWDField({ field: 'isManualEnterFromPhenotype', value: false });
        break;
      case aiButtonsEnum.SAVE_PHENOTYPE_TO_PICO:
        dispatch(clearStudyFormats());
        handleUpdateChatRWDData({
          selectedSuggestionsOptions: [],
          isManualEnterFromPhenotype: false,
          isPICOTFinalized: false,
          isPhenotypeFinalized: false,
        });
        handleSavePhenotypeToPICOWorkflow();
        break;
      case aiButtonsEnum.MANUALLY_EDIT_QUESTION:
        handleUpdateChatRWDData({
          hasWorkflowStarted: false,
          isClinicalRetry: true,
        });
        break;
      case aiButtonsEnum.MANUALLY_EDIT:
        handleCurrentStudyFormatUpdate({ isShow: true, index });
        break;
      case aiButtonsEnum.CANCEL_CURRENT_FORMAT_EDIT:
        if (ordersPageData?.chatRWD?.isManualEnterFromPhenotype) {
          const data = {
            isManualEnterFromPhenotype: false,
            isPICOTFinalized: true,
          };
          handleUpdateChatRWDData(data);
          handleCurrentStudyFormatUpdate({
            isShow: false,
            index: studyFormats.length - 1,
          });
        } else {
          handleCurrentStudyFormatUpdate({ isShow: false, index });
        }
        break;
      case aiButtonsEnum.SAVE_CURRENT_FORMAT_EDIT:
        if (ordersPageData?.chatRWD?.isManualEnterFromPhenotype) {
          dispatch(resetPhenotypeData());
          handleSaveCurrentFormatEdit(index);
          removeSelectedOptionWithIndex(
            ordersPageData?.chatRWD?.selectedSuggestionsOptions?.length - 1,
          );
          handleUpdateChatRWDField({ field: 'isManualEnterFromPhenotype', value: false });
        } else {
          handleSaveCurrentFormatEdit(index);
        }
        break;
      case aiButtonsEnum.MANUALLY_EDIT_PICOT_ON_ERROR:
        handleManualEditPICOTonErrorWorkflow();
        break;
      case aiButtonsEnum.SUGGEST_IMPROVEMENTS:
        fetchSuggestionsResponseThroughOpenAi();
        break;
      case aiButtonsEnum.REGENERATE_SUGGESTIONS:
        handleUpdateChatRWDField({
          field: 'checkedSuggestionsOptions',
          value: {
            ...ordersPageData?.chatRWD?.checkedSuggestionsOptions,
            [index]: [],
          },
        });
        const updatedStudyFormats = removeStudyFormatWithIndex(index);
        dispatch(updateStudyDetails(updatedStudyFormats));
        fetchSuggestionsResponseThroughOpenAi();
        break;
      case aiButtonsEnum.INCORPORATE_CHANGES:
        // const curSuggestions = studyFormats?.[studyFormats.length - 1]?.suggestions || [];
        // handleSuggestionIncorporation({ options, curSuggestions });

        handleIncorporateOpenAISuggestions(options);
        break;
      case aiButtonsEnum.KEEP_ORIGINAL:
        const checkedOptions = {
          ...ordersPageData?.chatRWD?.checkedSuggestionsOptions,
          [index]: [],
        };
        handleUpdateChatRWDField({
          field: 'checkedSuggestionsOptions',
          value: checkedOptions,
        });
        handleSaveEditStudyFormat(currentStudyFormat);
        break;
      case aiButtonsEnum.SUGGESTIONS_MANUALLY_EDIT:
        handleSuggestionManuallyEdit();
        break;
      case aiButtonsEnum.TRY_AGAIN:
        handleTryAgainWorkflow();
        break;
      case aiButtonsEnum.MANUALLY_EDIT_ON_INCORPORATION_ERROR:
        handleOnIncorporationError();
        break;
      default:
        console.log(action, 'wrong option');
    }
  }

  function handleTryAgainWorkflow() {
    switch (userAction.action) {
      case aiButtonsEnum.GENERATE_PICO:
      case aiButtonsEnum.PICOT_ACCURATE:
      case aiButtonsEnum.REGENERATE_PICOT:
        handleButtonAction({ key: userAction.action });
        break;
      case aiButtonsEnum.SUGGEST_IMPROVEMENTS:
        handleRemoveErrorFrame();
        fetchSuggestionsResponseThroughOpenAi();
        break;
      case aiButtonsEnum.INCORPORATE_CHANGES:
        handleRemoveErrorFrame();
        handleIncorporateOpenAISuggestions(userAction?.options);
        // const curSuggestions = studyFormats?.[studyFormats.length - 2]?.suggestions || [];
        // handleSuggestionIncorporation({
        //   options: { ...userAction?.options?.options },
        //   curSuggestions,
        // });
        break;
      default:
        break;
    }
  }

  function handleUpdateChatRWDField({ field, value }) {
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field,
        value,
      },
    });
  }

  function handleUpdateChatRWDData(data) {
    dispatchOrderPageAction({
      type: 'order/setChatRWDData',
      payload: data,
    });
  }

  function handleUpdatePICOField({ field, value }) {
    dispatchOrderPageAction({
      type: 'order/updatePICOOrderDetailsField',
      payload: {
        field,
        value,
      },
    });
  }

  function handleCurrentStudyFormatUpdate({ isShow, index }) {
    const studyFormatsArr = structuredClone(studyFormats);
    const curFormat = studyFormatsArr[index];
    curFormat.isEdit = isShow;
    assignFormValues({ studyFormat: curFormat?.study_format, isShow, curFormat });

    handleUpdateChatRWDField({
      field: 'selectedStudyFormat',
      value: curFormat?.study_design || ordersPageData?.chatRWD?.selectedStudyFormat,
    });

    if (curFormat?.study_design === labels.crossSectional) {
      handleShowCohortStudies({
        format: curFormat?.study_format,
      });
    }

    dispatch(updateStudyDetails(studyFormatsArr));
  }

  function removeStudyFormatWithIndex(index) {
    const studyFormatsArr = structuredClone(studyFormats);
    studyFormatsArr.splice(index, 1);
    return studyFormatsArr;
  }

  function removeSelectedOptionWithIndex(index) {
    const selectedOptions = [...selectedSuggestionsOptions];
    selectedOptions.splice(index, 1);
    handleUpdateChatRWDField({
      field: 'selectedSuggestionsOptions',
      value: selectedOptions,
    });
  }

  function fetchOrderResponseThroughOpenAi({ questionText, isClinicalRetry }) {
    if (token) {
      dispatch(
        fetchResultThroughOpenAi({
          params: {
            question: questionText,
            previous_pico_guid: PICOGuid,
            clinical_retry: isClinicalRetry,
          },
          token: token,
        }),
      );
    } else {
      console.error('Token is not available');
    }
  }

  function handleRegenerateStudyDetails() {
    if (token) {
      dispatch(
        fetchResultThroughOpenAi({
          params: {
            question: questionTitle,
            clinical_retry: false,
          },
          token: token,
        }),
      );
    } else {
      console.error('Token is not available');
    }
  }

  function fetchPhenotype() {
    if (token) {
      dispatch(
        fetchPicoToPhenotype({
          params: {
            pico_guid: PICOGuid,
          },
          token: token,
        }),
      );
    } else {
      console.error('Token is not available');
    }
  }

  function handleGeneratePICOWorkflow() {
    handleUpdateChatRWDField({ field: 'hasWorkflowStarted', value: true });
    if (!initialClinicalQuestions) {
      handleUpdateChatRWDField({
        field: 'initialClinicalQuestions',
        value: questionTitle,
      });
    }
    fetchOrderResponseThroughOpenAi({
      questionText: questionTitle,
      isClinicalRetry,
    });
    if (isClinicalRetry) {
      handleUpdateChatRWDField({ field: 'isClinicalRetry', value: false });
    }
  }

  function handleSaveCurrentFormatEdit(index) {
    handleCurrentStudyFormatUpdate({ isShow: false, index });
    const updatedStudyFormats = removeStudyFormatWithIndex(index);
    dispatch(updateStudyDetails(updatedStudyFormats));
    handlePICOQuestionOnChange();
    // handleSaveEditStudyFormat(formValues);
  }

  function handleManualSavePICOWorkflow() {
    const question = handlePICOQuestionOnChange();

    let data = {
      hasWorkflowStarted: true,
      hasUserSelectedManualWorkflow: true,
      isManualInputMode: false,
      questionTitle: question,
    };
    if (!initialClinicalQuestions) data['initialClinicalQuestions'] = question;
    if (studyFormats && studyFormats.length > 0) {
      data['selectedSuggestionsOptions'] = [];
      dispatch(resetStudyFormats());
    }
    handleUpdateChatRWDData(data);
    // handleSaveEditStudyFormat(formValues);
  }

  function handleSavePhenotypeToPICOWorkflow() {
    handlePICOQuestionOnChange();
    handleSaveEditStudyFormat(formValues);
  }

  function handlePICOQuestionOnChange() {
    const question = constructQuestionFromStudyFormat(formValues);
    handleUpdatePICOField({ field: 'clinicalQuestion', value: question });
    return question;
  }

  function handleSaveEditStudyFormat(formValues) {
    dispatch(
      savePicoOptions({
        params: {
          previous_pico_guid: PICOGuid,
          study_design: study_design,
          study_format: {
            ...formValues,
          },
        },
        token,
      }),
    );
  }

  function scrollToBottom() {
    setTimeout(
      () =>
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth',
        }),
      100,
    );
  }

  function fetchSuggestionsResponseThroughOpenAi() {
    dispatch(
      fetchSuggestionsThroughOpenAi({
        params: {
          pico_guid: PICOGuid,
        },
        token,
      }),
    );
  }

  function extractPICOOptions(option) {
    const picoOptions = {};
    for (const key in option) {
      picoOptions[key] = option[key].replace(/^\d+\. /, '');
    }
    return picoOptions;
  }

  function handleSuggestionIncorporation({ options, curSuggestions }) {
    const selectedOptions = extractPICOOptions(options);
    const selectedSuggestions = curSuggestions?.filter((item) =>
      Object.values(selectedOptions).includes(item.name),
    );
    handleIncorporateOpenAISuggestions(selectedSuggestions);
  }

  function handleIncorporateOpenAISuggestions(suggestions) {
    dispatch(
      incorporateSuggestionsThroughOpenAi({
        params: {
          pico_guid: PICOGuid,
          selected_suggestions: suggestions,
        },
        token,
      }),
    );
  }

  function handleManualEditPICOTonErrorWorkflow() {
    handleUpdateChatRWDField({
      field: 'isPICOTFinalized',
      value: false,
    });
    const curFormatindex = studyFormats.findIndex(
      (studyFormat) => studyFormat.guid === PICOGuid,
    );
    if (curFormatindex >= 0) {
      handleCurrentStudyFormatUpdate({ isShow: true, index: curFormatindex });
      dispatch(resetPhenotypeData());
      removeSelectedOptionWithIndex(curFormatindex);
    }
  }

  function handleRemoveErrorFrame() {
    const studyFormatsArr = [...studyFormats];
    studyFormatsArr.pop();
    dispatch(updateStudyDetails(studyFormatsArr));
  }

  function handleSuggestionManuallyEdit() {
    const { options } = userAction;
    const prevStudyFormatIndex = studyFormats.findIndex(
      (studyFormat) => studyFormat.guid === options.PICOGuid,
    );

    if (prevStudyFormatIndex >= 0) {
      const arr = studyFormats.slice(0, prevStudyFormatIndex + 1);
      arr[prevStudyFormatIndex].isEdit = true;
      const selectedOptionsArr = selectedSuggestionsOptions.slice(
        0,
        prevStudyFormatIndex,
      );

      assignFormValues({ studyFormat: arr[0]?.study_format, isShow: true });
      handleUpdateChatRWDField({
        field: 'selectedStudyFormat',
        value: arr[0]?.study_design || ordersPageData?.chatRWD?.selectedStudyFormat,
      });

      handleUpdateChatRWDField({
        field: 'selectedSuggestionsOptions',
        value: selectedOptionsArr,
      });
      dispatch(updateStudyDetails(arr));
    }
  }

  function handleShowCohortStudies({ format }) {
    const cohortKeys = Object.keys(format).filter(
      (key) => key.startsWith('cohort_') && format[key],
    );

    const maxCohortNumber = Math.max(
      ...cohortKeys.map((key) => parseInt(key.split('_')[1])),
    );
    const visibleFieldsArray = ['population'];

    for (let i = 1; i <= maxCohortNumber; i++) {
      visibleFieldsArray.push(`cohort_${i}`);
    }

    visibleFieldsArray.push('outcomes');
    const nextFieldIndex =
      maxCohortNumber === 5 ? 6 : maxCohortNumber <= 2 ? 3 : maxCohortNumber + 1;
    handleUpdateChatRWDData({
      visibleFields: visibleFieldsArray,
      nextFieldIndex,
    });
  }

  function assignFormValues({ studyFormat, isShow, curFormat }) {
    const { intervention, cohort_1, cohort_2, control } = studyFormat;
    const interventionValue = !intervention && cohort_1 ? cohort_1 : intervention;
    const controlValue = !control && cohort_2 ? cohort_2 : control;
    const cohortFirstValue = intervention && !cohort_1 ? intervention : cohort_1;
    const cohortSecondValue = control && !cohort_2 ? control : cohort_2;

    const format = {
      ...studyFormat,
      intervention: interventionValue,
      control: controlValue,
      cohort_1: cohortFirstValue,
      cohort_2: cohortSecondValue,
    };

    dispatchOrderPageAction({
      type: 'order/updateChatRWDFormValues',
      payload: isShow ? format : {},
    });
  }

  function handleOnIncorporationError() {
    const curStudyIndex = studyFormats.findIndex((format) => format?.guid === PICOGuid);
    const curStudyFormat = studyFormats.find((format) => format?.guid === PICOGuid);

    if (curStudyIndex >= 0) {
      removeSelectedOptionWithIndex(curStudyIndex);

      const formatsToRemove = studyFormats.slice(0, curStudyIndex + 1);
      formatsToRemove[formatsToRemove.length - 1].isEdit = true;
      assignFormValues({
        studyFormat: curStudyFormat?.study_format,
        isShow: true,
      });

      const optionstoRemove = selectedSuggestionsOptions.slice(0, curStudyIndex);

      const checkedOptions = structuredClone(checkedSuggestionsOptions);
      const keys = Object.keys(checkedOptions);
      const lastKey = keys[keys.length - 1];
      delete checkedOptions[lastKey];

      handleUpdateChatRWDData({
        selectedSuggestionsOptions: optionstoRemove,
        checkedSuggestionsOptions: checkedOptions,
        selectedStudyFormat: curStudyFormat?.study_design || selectedStudyFormat,
      });

      dispatch(updateStudyDetails(formatsToRemove));
    }
  }

  function renderIconTitle({ icon }) {
    switch (icon) {
      case aiButtonsEnum.REGENERATE_PHENOTYPES:
        return labels.regeneratePhenotypes;
      case aiButtonsEnum.MANUALLY_EDIT_PICO:
      case aiButtonsEnum.MANUALLY_EDIT:
        return labels.manuallyEdit;
      case aiButtonsEnum.REGENERATE_PICOT:
        return labels.regenerateStudy;
      case aiButtonsEnum.REGENERATE_SUGGESTIONS:
        return labels.regenerateSuggestions;
    }
  }

  function handleIsStepFailed(obj) {
    if (!obj || Object.keys(obj).length === 0) {
      return false;
    }
    const buttons = obj.buttons || {};
    if (Object.keys(buttons).length === 0) {
      return true;
    }
    if ('TRY_AGAIN' in buttons && 'START_OVER' in buttons) {
      return true;
    }
    return false;
  }

  const curObj =
    (studyFormats && studyFormats.length > 0 && studyFormats[studyFormats.length - 1]) ||
    {};

  const isPICOStepFailed = handleIsStepFailed(curObj);
  const isPhenotypeStepFailed = handleIsStepFailed(phenotypeResponse);
  const isPICOOrPhenotypeStepFailed =
    isPICOStepFailed ||
    (isPhenotypeStepFailed && hasMotivationSelectChanged) ||
    isUnableToReturnPhenotype;

  return (
    <OrderPageContext.Provider
      value={{
        ordersPageData,
        dispatchOrderPageAction,
        isFormValid,
        orderPayloadData,
        isReorder,
        orderTypePath,
        showButtonIcons,
        handleLoadingStrings,
        handleReset,
        handleButtonAction,
        token,
        hasZeroOutcomeMaxValue,
        isChatRWDFormValid,
        isUserInViewAsMode,
        isFreeOrderLimitReached,
        isUnableToReturnPhenotype,
        renderIconTitle,
        isPICOOrPhenotypeStepFailed,
      }}
    >
      {children}
    </OrderPageContext.Provider>
  );
}

function useOrdersPagedata() {
  const context = useContext(OrderPageContext);
  if (context === undefined) throw new Error('context used outside');
  return context;
}

export { OrderPageProvider, useOrdersPagedata };
