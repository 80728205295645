import React from 'react';
import tickCircle from '../../assets/images/tick-circle.png';
import closeCircle from '../../assets/images/close-circle.png';
import { ButtonCustom } from 'components/form-input/Button';

const NotificationBanner = ({
  onClickNotificationLink,
  onCloseNotificationBanner,
  allNotificationList,
}) => {
  const { banner_failure_unread_count, banner_success_unread_count } =
    allNotificationList;

  const handleNotificationDropdown = (type) => {
    const obj = {
      ...type,
      bell_unread_count: true,
    };
    onClickNotificationLink(obj);
  };

  return (
    <>
      {allNotificationList && banner_success_unread_count !== 0 && (
        <div className="snackbar-container mb-1 notification-banner">
          <div className="snackbar-wrapper">
            <div className="d-flex align-items-center">
              <img className="snackbar-img" src={tickCircle} alt="img" />
              <div className="ml-3 snackbar-message">
                Your Prognostogram(s) completed! Please&nbsp;
                <ButtonCustom
                  cssClass="bg-black border-none txt size-15"
                  onClick={() =>
                    handleNotificationDropdown({ banner_success_unread_count: true })
                  }
                  buttonAction={'Notification success banner click here button clicked'}
                  id="notification-success-banner-click-here-button"
                >
                  <span className="banner-link cursor-pointer">Click here</span>
                </ButtonCustom>
                &nbsp;to view the result(s).
              </div>
            </div>
            <div>
              <ButtonCustom
                cssClass="bg-black text-white border-none cursor-pointer txt size-16 p-0"
                onClick={() =>
                  onCloseNotificationBanner({ banner_success_unread_count: true })
                }
                buttonAction={'Notification success banner close button clicked'}
                id="notification-success-banner-close-button"
              >
                x
              </ButtonCustom>
            </div>
          </div>
        </div>
      )}
      {allNotificationList && banner_failure_unread_count !== 0 && (
        <div className="snackbar-container mb-1 notification-banner">
          <div className="snackbar-wrapper">
            <div className="d-flex align-items-center">
              <img className="snackbar-img" src={closeCircle} alt="img" />
              <div className="ml-3 snackbar-message">
                Your Prognostogram(s) failed to generate. Please&nbsp;
                <ButtonCustom
                  cssClass="bg-black border-none txt size-15"
                  onClick={() =>
                    handleNotificationDropdown({ banner_failure_unread_count: true })
                  }
                  buttonAction={'Notification failure banner click here button clicked'}
                  id="notification-failure-banner-click-here-button"
                >
                  <span className="banner-link cursor-pointer">Click here</span>
                </ButtonCustom>
                &nbsp;for more details or contact us at{' '}
                <a href="mailto:support@atroposhealth.com">support@atroposhealth.com</a>.
              </div>
            </div>
            <div>
              <ButtonCustom
                cssClass="bg-black text-white border-none cursor-pointer txt size-16 p-0"
                onClick={() =>
                  onCloseNotificationBanner({ banner_failure_unread_count: true })
                }
                buttonAction={'Notification failure banner close button clicked'}
                id="notification-failure-banner-close-button"
              >
                x
              </ButtonCustom>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationBanner;
