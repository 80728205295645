import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNotificationGuid,
  getBadgeNotifications,
  toggleNotificationDropdown,
  getBadgeNotificationsComplete,
} from 'redux/modules/notifications/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { getRawToken } from 'utils/validations';
import InfiniteScroll from 'react-infinite-scroll-component';
import AuthLoader from 'components/AuthLoader';
import Notification from './Notification';
import { ButtonCustom } from 'components/form-input/Button';
import { useMixpanel } from 'react-mixpanel-browser';
import { ReactComponent as Close } from 'assets/images/Close.svg';

const NotificationBell = ({ allNotificationList, onClickNotificationBell }) => {
  const dispatch = useDispatch();
  const notificationRef = useRef(null);
  const mixpanel = useMixpanel();
  const { getIdTokenClaims } = useAuth0();
  const { bell_unread_count } = allNotificationList;
  const {
    pageNumber,
    badgeNotifications,
    badgeNotificationsCount,
    isNotificationDropdownOpen,
    isCountResetting,
  } = useSelector(({ notifications }) => notifications);
  const { userData } = useSelector(({ userProfile }) => userProfile);

  useEffect(() => {
    function handler(event) {
      if (
        isNotificationDropdownOpen &&
        !notificationRef.current?.contains(event.target) &&
        !event.target.closest('.notification-banner')
      ) {
        dispatch(toggleNotificationDropdown(false));
        dispatch(getBadgeNotificationsComplete([]));
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [isNotificationDropdownOpen]);

  const handleNotificationRead = (notificationGuid) => {
    dispatch(setNotificationGuid(notificationGuid));
  };

  const handleNotificationResetCount = () => {
    if (isNotificationDropdownOpen) {
      dispatch(toggleNotificationDropdown(false));
    } else {
      const obj = {
        bell_unread_count: true,
      };
      onClickNotificationBell(obj);
    }

    mixpanel.track('Notification bell icon clicked', {
      action_value: 'Bell Icon',
      action: 'Bell Icon',
    });
  };

  const fetchMoreData = async () => {
    if (badgeNotificationsCount.total_pages >= pageNumber) {
      const token = await getIdTokenClaims();
      dispatch(
        getBadgeNotifications({
          token: getRawToken(token),
          userGuid: userData.guid,
          page: pageNumber,
          size: 10,
        }),
      );
    }
  };

  return (
    <div className="notification-dropdown-container">
      <div className="position-relative" ref={notificationRef}>
        <div
          onClick={handleNotificationResetCount}
          className="bell-icon-img cursor-pointer"
        >
          <div className="img bell"></div>
          {allNotificationList &&
            allNotificationList.hasOwnProperty('bell_unread_count') &&
            allNotificationList.bell_unread_count !== 0 &&
            !isCountResetting && <div className="unread-count">{bell_unread_count}</div>}
        </div>
        {isNotificationDropdownOpen && (
          <div className="notifications-dropdowncontainer">
            <div className="notification-header">
              <div className="d-flex justify-content-end cursor-pointer">
                <ButtonCustom
                  cssClass="bg-white border-none cursor-pointer"
                  onClick={() => dispatch(toggleNotificationDropdown(false))}
                  buttonAction={'Notification dropdwon close button clicked'}
                  id="notification-dropdwon-close-button"
                >
                  <Close />
                </ButtonCustom>
              </div>
              <div className="txt size-20 w-700 dark">Notifications</div>
            </div>
            {isCountResetting ? (
              <div className="empty-notifications">
                <AuthLoader fullScreen={false} />
              </div>
            ) : allNotificationList && allNotificationList?.result?.length > 0 ? (
              <div
                id="scrollableDiv"
                style={{
                  maxHeight: 350,
                  overflow: 'auto',
                }}
              >
                <InfiniteScroll
                  scrollableTarget="scrollableDiv"
                  dataLength={badgeNotifications?.length}
                  next={fetchMoreData}
                  hasMore={
                    badgeNotificationsCount &&
                    badgeNotificationsCount.total_pages >= pageNumber
                  }
                  loader={
                    <div
                      className={`position-relative ${
                        badgeNotifications.length === 0 && 'h-300'
                      }`}
                    >
                      <AuthLoader fullScreen={false} />
                    </div>
                  }
                >
                  <div className="d-flex flex-column gap-19">
                    <div>
                      {badgeNotifications &&
                        badgeNotifications.length > 0 &&
                        badgeNotifications.map((notification, index) => (
                          <React.Fragment key={notification.guid}>
                            <Notification notificationObj={notification} />
                            {index !== badgeNotifications.length - 1 && (
                              <hr className="mb-3" />
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            ) : (
              <div className="empty-notifications">No New Notifications</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationBell;
