export const labels = {
  orderaPrognostogram: 'Order a Prognostogram',
  modifyandReorder: 'Modify and Reorder',
  editOrder: 'Edit Order',
  accessErrorMessage:
    'Oops! It looks like you do not have access to that Prognostogram. If you think this is an error, please reach out to our team at',
  email: 'Support@atroposhealth.com',
  confirmAndSendRequest: 'Confirm and Send Request',
  orderYourFirstPrognostogram: 'Order Your First Prognostogram!',
  scheduleaCall: 'Schedule a Call',
  modifyAndReorder: 'Modify and Reorder',
  editOrder: 'Edit Order',
  unableToReturnPhenotypeDescription: 'unable to return an appropriate phenotype',
  failedToGeneratePhenotype: 'Failed to generate the phenotype',
  patientCare: 'patient care',
  inputQuestionPlaceholder:
    'Enter your question here (example: Is there a difference in incidents of fractures in female oncology patients without a history of osteoporosis who received zoledronic acid vs denosumab?) and press return.',
  enterYourQuestion: 'Enter your question below',
  oneMoreStep: 'One more step! Please set your study parameters:',
  studyYears: 'Study Years',
  downsampling: 'Downsampling',
  minimumLengthofPatientRecord: 'Minimum Length of Patient Record ',
  preIndex: 'Pre-Index',
  postIndex: 'Post-Index',
  caseSeries: 'case series',
  crossSectional: 'cross-sectional',
  comparativeEffectiveness: 'comparative effectiveness',
  placeholderCopy: 'Please choose your study format and enter the study parameters.',
  crossSectional: 'cross-sectional',
  intervention: 'intervention',
  control: 'control',
  cohort_1: 'cohort_1',
  cohort_2: 'cohort_2',
  manualEdit: 'manualEdit',
  studyParametersAccurate: 'Study Parameters Accurate',
  cancelRequest: 'Cancel Request',
  conflicting_ages: 'conflicting_ages',
  no_sex_selected: 'no_sex_selected',
  conflicting_sexes: 'conflicting_sexes',
  population: 'population',
  submitMotivation: 'Submit Motivation',
  studyAgeRange: 'Age Range - please select the MIN and MAX ages for your study',
  studySexSelection: 'Sex - please select the sexes to include',
  viewAs: 'View As',
  pleaseEnterEmailAddress: 'Please enter email address.',
  ahSalesEmail: 'sales@atroposhealth.com',
  vocabulary: 'Vocabulary',
  code: 'Code',
  description: 'Description',
  supportEmail: 'support@atroposhealth.com',
  aiSearch: 'ai-search',
  regularSearch: 'regular-search',
  answerWithAI: 'Answer with AI',
  basicSearch: 'Basic Search',
  clear: 'clear',
  SOURCES: 'SOURCES',
  ANSWER: 'ANSWER',
  generateAdditionalEvidence: 'Generate Additional Evidence',
  suggestedFollowupQuestions: 'Suggested Follow-up Questions',
  otherSources: 'Other Sources',
  simpleSearch: 'Simple Search',
  ai_search: 'AI Search',
  regeneratePhenotypes: 'Regenerate Phenotypes',
  manuallyEdit: 'Manually Edit',
  regenerateStudy: 'Regenerate Study',
  regenerateSuggestions: 'Regenerate Suggestions',
  Publications: 'Publications',
  atroposHealth: 'Atropos Health',
  atroposSummary: 'atropos summary',
  publicationSummary: 'publication summary',
  theme: 'theme',
  summary: 'summary',
  score: 'score',
  reasoning: 'reasoning',
  noSummaryAvailable: 'No summary available.',
  noSourcesAvailable: 'No sources available.',
  chooseDataSource: 'Please choose your data source below.',
  rankedDataSources:
    'We ranked the data sources based on the phenotypes in your study design. ',
  confirmDataSet: 'Confirm Data Source',
  freeText: 'free text',
  enterAsStudyParameters: 'Enter as Study Parameters',
  enterAsFreeText: 'Enter as Free Text',
  scheduleWithProvider: 'Schedule With a Provider ',
  submitYourQuestion: 'Submit Your Question',
  upgradeAccount: 'Upgrade Account',
  noMatchForClinicalQuestionMessage:
    "We couldn't find any relevant matches for the clinical question you submitted. If you need assistance, you can submit your question to our clinical team for further analysis through our Green Button service, or schedule an immediate consultation with them.",
  atroposContactUs: 'https://www.atroposhealth.com/contact-us/',
  supportNotice:
    'Unfortunately, ChatRWD isn’t able to answer your question at this time. To get your question answered, you can submit it to our clinical team for analysis through our Green Button service or schedule an immediate consultation with them.',
  free: 'free',
  clinicalSubmissionMessage:
    'Unfortunately, ChatRWD isn’t suited to answer your question. We recommend submitting your question to the clinical team using Green Button. Any clinical concepts generated during your ChatRWD flow will also be sent to the clinical team. Please confirm the details below.',
  studyDesign: 'study design',
  explanation: 'explanation',
  study: 'study',
  studyAdjustments: 'Study Adjustments to Improve Query Performance',
};
