import {
  GET_SEARCH_SUGGESTION_START,
  GET_SEARCH_SUGGESTION_COMPLETE,
  GET_SEARCH_SUGGESTION_ERROR,
  CLEAR_SEARCH_TERM_DATA,
  SET_IS_AI_SEARCH_MODE,
} from './actions';
import * as searchOrderTypes from './types';

let initialState = {
  waitingForSearchTerms: false,
  aiSearchState: {
    isAISearchMode: false,
    aiSearchResults: [],
    sources: [],
    aiSearchLoading: false,
    aiSearchError: undefined,
    PubMedSearchLoading: false,
  },
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_SEARCH_SUGGESTION_START:
      return {
        ...state,
        getSearchTermsError: undefined,
        waitingForSearchTerms: true,
      };
    case GET_SEARCH_SUGGESTION_COMPLETE:
      return {
        ...state,
        getSearchTermsError: undefined,
        waitingForSearchTerms: false,
        searchTerms: payload.searchTerms,
      };
    case GET_SEARCH_SUGGESTION_ERROR:
      return {
        ...state,
        getSearchTermsError: payload.error,
        waitingForSearchTerms: false,
        ...payload,
      };
    case CLEAR_SEARCH_TERM_DATA:
      return {
        ...state,
        searchTerms: [],
        ...payload,
      };
    case searchOrderTypes.SET_IS_AI_SEARCH_MODE:
      return {
        ...state,
        aiSearchState: {
          ...state.aiSearchState,
          isAISearchMode: payload,
        },
      };
    case searchOrderTypes.AI_SEARCH_LOADING:
      return {
        ...state,
        aiSearchState: {
          ...state.aiSearchState,
          aiSearchLoading: true,
          aiSearchResults: [],
          aiSearchError: undefined,
          sources: [],
          synthesisThemes: [],
        },
      };
    case searchOrderTypes.AI_SEARCH_COMPLETE:
      const cursources = payload?.result[0]?.sources || [];
      const prevSources = state.aiSearchState.sources;
      const sources = [...cursources, ...prevSources];

      return {
        ...state,
        aiSearchState: {
          ...state.aiSearchState,
          aiSearchLoading: false,
          aiSearchResults: payload,
          sources,
        },
      };
    case searchOrderTypes.AI_SEARCH_ERROR:
      return {
        ...state,
        aiSearchState: {
          ...state.aiSearchState,
          aiSearchLoading: false,
          aiSearchResults: [],
          aiSearchError: payload.error,
        },
      };
    case searchOrderTypes.SET_AI_SEARCH_LOADING:
      return {
        ...state,
        aiSearchState: {
          ...state.aiSearchState,
          aiSearchLoading: payload,
        },
      };
    case searchOrderTypes.STOP_BACKGROUND_SYNC_COMPLETE:
      return {
        ...state,
        aiSearchState: {
          ...initialState.aiSearchState,
        },
      };

    case searchOrderTypes.FETCH_PUBMED_LOADING:
      return {
        ...state,
        aiSearchState: {
          ...state.aiSearchState,
          PubMedSearchLoading: true,
          pubmedSearchError: undefined,
        },
      };
    case searchOrderTypes.FETCH_PUBMED_SUCCESS:
      const pubmedCursources = payload?.study_sources || [];
      const pubmedPrevSources = state.aiSearchState.sources;
      const newSources = [...pubmedPrevSources, ...pubmedCursources];

      return {
        ...state,
        aiSearchState: {
          ...state.aiSearchState,
          PubMedSearchLoading: false,
          sources: newSources,
          synthesisThemes: payload?.synthesis_themes,
        },
      };
    case searchOrderTypes.FETCH_PUBMED_FAILURE:
      return {
        ...state,
        aiSearchState: {
          ...state.aiSearchState,
          PubMedSearchLoading: false,
          pubmedSearchError: payload.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;
