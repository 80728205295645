import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { INPUT_SIZE } from 'constants/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOrdersPagedata } from '../../pages/order/OrderPageContext';
import {
  modifyPrognostogramOrder,
  orderPrognostogram,
  clearOrderFormData,
  resetOrderIndexData,
} from 'redux/modules/orderDetails/actions';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import FormButtons from '../../pages/order/FormButtons';
import { GreenButtonPicotOrderForm } from './components';
import Error from 'components/Error';
import { InputTextarea } from 'components/form/InputTextarea';
import { labels } from 'constants/labels';

function GreenButtonOrder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isUploadingDocument,
    singleOrderData,
    orderPrognostogramError,
    orderTaskStatusError,
    phenotypeResponse,
    PICOGuid,
  } = useSelector(({ orderDetails }) => orderDetails);

  const {
    isFormValid,
    orderPayloadData,
    dispatchOrderPageAction,
    isReorder,
    orderTypePath,
    ordersPageData: {
      PICOOrderDetails,
      selectedDataSourceOption,
      greenButtonQuestionSelection,
    },
    token,
    isUserInViewAsMode,
    isPICOOrPhenotypeStepFailed,
  } = useOrdersPagedata();

  const isPhenotypeAndStepFail =
    phenotypeResponse?.phenotypes &&
    Object.keys(phenotypeResponse?.phenotypes).length > 0 &&
    isPICOOrPhenotypeStepFailed;

  const { clinicalQuestion, initialClinicalQuestions } = PICOOrderDetails;

  const onInputChange = (e, name) => {
    if (e.target.value.length <= INPUT_SIZE) {
      const data = {
        [name]: e.target.value,
      };

      dispatchOrderPageAction({
        type: 'order/PICOOrderDetails',
        payload: data,
      });
    } else {
      e.preventDefault();
    }
  };

  const submitOrder = () => {
    if (isFormValid()) {
      dispatch(resetOrderIndexData());
      dispatchOrderPageAction({
        type: 'order/seIsOrderSubmitted',
        payload: true,
      });

      const order = {
        ...orderPayloadData,
        order: {
          ...orderPayloadData?.order,
          initial_clinical_questions: !initialClinicalQuestions
            ? clinicalQuestion
            : initialClinicalQuestions,
          clinical_questions: clinicalQuestion,
          question_title: clinicalQuestion,
          pico_guid: '',
          product_case_type: 'PG',
          population: '',
          intervention: '',
          control: '',
          outcome: '',
          timeframe: '',
          data_source: selectedDataSourceOption,
          ...(isPhenotypeAndStepFail && {
            pg_workflow_type: 'chatRwdToGB',
            user_phenotypes: phenotypeResponse?.phenotypes,
            pico_guid: PICOGuid,
          }),
        },
      };

      if (isReorder) {
        dispatch(
          modifyPrognostogramOrder({
            params: order,
            orderGuid: singleOrderData?.guid,
            token: token || '',
            isChatrwd: false,
            navigateToDashboard: navigateToDashboard,
          }),
        );
      } else {
        dispatch(
          orderPrognostogram({
            params: order,
            csrfToken: Cookies.get('csrftoken'),
            token: token || '',
            isChatrwd: false,
            navigateToDashboard: navigateToDashboard,
          }),
        );
      }
    }
  };

  const navigateToDashboard = () => {
    dispatchOrderPageAction({
      type: 'order/seIsOrderSubmitted',
      payload: false,
    });
    dispatch(clearOrderFormData());
    history.push(urlConstantsEnum.ORDER_SUBMITTED);
  };

  return (
    <div>
      <div>
        {greenButtonQuestionSelection === 0 ? (
          <>
            <div className="d-flex flex-column gap-25">
              {isPICOOrPhenotypeStepFailed && (
                <div className="txt size-16 lh-24">
                  {labels.clinicalSubmissionMessage}
                </div>
              )}
              <InputTextarea
                placeholder="Write your clinical question here.."
                value={clinicalQuestion}
                onChange={(e) => onInputChange(e, 'clinicalQuestion')}
                id="textarea-clinical-question"
              />
            </div>
            <div className="mt-3">
              <FormButtons
                disabled={!clinicalQuestion || isUploadingDocument || isUserInViewAsMode}
                onSubmit={submitOrder}
                buttonAction="Submit order button clicked, order type PG"
                actionLabel="New PG order is submitted"
                id="btn-submit-order-button"
              />
            </div>
          </>
        ) : (
          <GreenButtonPicotOrderForm />
        )}
      </div>

      {!!orderPrognostogramError || !!orderTaskStatusError ? (
        <Error error={orderPrognostogramError || orderTaskStatusError} />
      ) : (
        ''
      )}
    </div>
  );
}

export default GreenButtonOrder;
