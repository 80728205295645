import { ButtonLink } from 'components/form-input/Button';
import React from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import {
  AccordionWithHeader,
  AccordionNode,
  AccordionHeader,
  AccordionPanel,
} from 'react-accordion-with-header';
import { useSelector } from 'react-redux';
import AiSummary from './AiSummary';

function AIOrderDetailLayout({ aiSummary }) {
  const { aiSearchState = {} } = useSelector(({ searchOrder }) => searchOrder);
  const { aiSearchResults } = aiSearchState;
  const order = aiSearchResults?.result?.length && aiSearchResults.result[0];

  return (
    <>
      <AccordionWithHeader
        className="accordian-container mt-4 mt-md-0"
        multipleOkay={false}
        firstOpen={true}
      >
        <AccordionNode className="single-prognostorm order-ai-summary  position-relative text-bold">
          <AccordionHeader className="accordian-header w-100 ai-search-accordian-header justify-content-between">
            <div className="txt size-16 lh-22 w-700 text-primary-1 pr-md-6">
              AI SUMMARY
            </div>

            <div className="down-arrow">
              <ButtonLink buttonAction="order expand button clicked">
                <HiOutlineChevronDown />
              </ButtonLink>
            </div>
          </AccordionHeader>
          <AccordionPanel className="accordian-panel">
            <div>
              <AiSummary summary={order} aiSummary={aiSummary} />
            </div>
          </AccordionPanel>
        </AccordionNode>
      </AccordionWithHeader>
    </>
  );
}

export default AIOrderDetailLayout;
