import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Strings } from 'constants/Strings';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { ButtonCustom } from 'components/form-input/Button';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import {
  resetChartrwdErrors,
  syncModifiedPhenotypeData,
} from 'redux/modules/orderDetails/actions';
import { useOrderPath } from 'hooks/useOrderPath';
import { useParams } from 'react-router-dom';
import { Frame } from 'components/Frame';
import { ReactComponent as Check } from 'assets/images/Check.svg';
import Phenotype from './Phenotype';
import { labels } from 'constants/labels';
import { ReactComponent as Refresh } from 'assets/images/Refresh.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { Button } from 'components/button';

const phenotypeDisabledButtons = {
  START_OVER: 'Start Over',
};

const defaultButtons = {
  TRY_AGAIN: 'Try Again',
  START_OVER: 'Start Over',
};

function PhenotypePage() {
  const {
    showButtonIcons,
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
    handleButtonAction,
    hasZeroOutcomeMaxValue,
    token,
    isUserInViewAsMode,
    isFreeOrderLimitReached,
    renderIconTitle,
    isUnableToReturnPhenotype,
  } = useOrdersPagedata();

  const dispatch = useDispatch();
  const params = useParams();
  const orderTypePath = useOrderPath();
  const { phenotypeResponse, isOpenAiLoading, PICOGuid } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const [phenotypeData, setPhenotypeData] = useState('');
  const [phenotypeTableResponseTextString, setPhenotypeTableResponseTextString] =
    useState('');
  const [picoObjectValue, setPicoObjectValue] = useState();
  const hasNullOutcomeMinValue = phenotypeResponse?.phenotypes?.outcomes?.entities?.some(
    (entity) => entity.outcome_min_value === null,
  );

  const isPhenotTypeButtonDisabled =
    hasZeroOutcomeMaxValue ||
    hasNullOutcomeMinValue ||
    isUnableToReturnPhenotype ||
    false;

  useEffect(() => {
    if (Object.keys(phenotypeData).length === 0) {
      setInitialPhenotypeData();
    }
  }, [phenotypeResponse]);

  useEffect(() => {
    var response = phenotypeData;
    var picoPObj = {};
    var isMatching = false;
    var isError = false;
    var count = 0;
    if (response !== undefined && response !== '' && response !== null) {
      for (const key in response) {
        if (response.hasOwnProperty(key)) {
          if (response[key] && Object.keys(response[key]).length !== 0) {
            picoPObj[key] = {};
            isError = false;
            isMatching = false;
            count = 0;
            if (response[key]?.entities?.length === 0) {
              picoPObj[key] = { isError: true, isMatching: isMatching, count: count };
            } else {
              for (let i = 0; i < response[key]?.entities?.length; i++) {
                if (
                  Object.prototype.toString
                    .call(response[key]?.entities[i]?.phenotypes)
                    .indexOf('Array') > -1
                ) {
                  count += response[key]?.entities[i]?.phenotypes[0]?.code_set?.length;
                  var phenotypeRankedObj = response[key]?.entities[i]?.phenotypes?.[0];
                  if (
                    phenotypeRankedObj?.code_set !== undefined &&
                    phenotypeRankedObj?.code_set.length !== 0 &&
                    phenotypeRankedObj?.tql !== null &&
                    phenotypeRankedObj?.tql?.toLowerCase() !== 'nan'
                  ) {
                    phenotypeRankedObj?.code_set?.map((code, index) => {
                      isMatching = true;
                      if (
                        labels.unableToReturnPhenotypeDescription ===
                        code?.concept_description?.toLowerCase()
                      ) {
                        isError = true;
                      } else {
                        isError = false;
                      }
                    });
                  } else {
                    isError = true;
                  }
                }
              }
              picoPObj[key] = { isError: isError, isMatching: isMatching, count: count };
            }
          }
        }
      }
    }
    showPhenotypetext(picoPObj);
  }, [phenotypeResponse, phenotypeData]);

  useEffect(() => {
    var response = phenotypeData;
    // setPhenotypesResponseObject(response);
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'userPhenotypes',
        value: response,
      },
    });
  }, [phenotypeData, picoObjectValue]);

  function checkKeyValues(picoObj, keyToCheck, valueToCheck) {
    if (Object.keys(picoObj).length !== 0) {
      for (const key in picoObj) {
        if (picoObj[key][keyToCheck] !== valueToCheck) {
          return false;
        }
      }
      return true;
    }
  }

  const showPhenotypetext = (picoObject) => {
    var isError = false;
    var text = '';
    setPicoObjectValue(picoObject);
    if (Object.keys(picoObject).length !== 0) {
      var isOnePicoPresent = checkKeyValues(picoObject, 'isMatching', true);
      for (const key in picoObject) {
        if (picoObject[key].isError) {
          isError = true;
        }
      }
      if (isPhenotTypeButtonDisabled) {
        text = Strings.phenotypeTableResponseText4;
      } else {
        if (isOnePicoPresent && isError) {
          text = Strings.phenotypeTableResponseText1;
        } else if (isError) {
          text = Strings.phenotypeTableResponseText2;
        } else {
          text = Strings.phenotypeTableResponseText3;
        }
      }

      setPhenotypeTableResponseTextString(text);
    }
  };

  const setInitialPhenotypeData = () => {
    if (phenotypeResponse?.phenotypes && phenotypeResponse !== undefined)
      setPhenotypeData(JSON.parse(JSON.stringify(phenotypeResponse?.phenotypes)));
  };

  function movePhenotypesAccurateToEnd(buttons) {
    if (!buttons) return buttons;

    if (buttons?.PHENOTYPES_ACCURATE) {
      let accurateValue = buttons.PHENOTYPES_ACCURATE;
      delete buttons.PHENOTYPES_ACCURATE;
      buttons.PHENOTYPES_ACCURATE = accurateValue;
    }
    return buttons;
  }

  const showPhenotypeResponseButtons = () => {
    var buttons = [];
    var button = '';

    var phenotypeButtons = phenotypeResponse?.buttons
      ? phenotypeResponse?.buttons
      : phenotypeButtons
      ? phenotypeButtons
      : defaultButtons;
    const buttonsObj = movePhenotypesAccurateToEnd(phenotypeButtons);

    if (buttonsObj) {
      for (const key in buttonsObj) {
        button = (
          <React.Fragment key={key}>
            {/* {key === 'PHENOTYPES_ACCURATE' && <div className="flex-basis-100"> </div>} */}
            <ButtonCustom
              key={key}
              cssClass={`${
                chatRWD.isPhenotypeFinalized && !hasZeroOutcomeMaxValue ? 'active-bg' : ''
              } ${
                key === aiButtonsEnum.PHENOTYPES_ACCURATE && isPhenotTypeButtonDisabled
                  ? 'btn-disabled'
                  : ''
              }  ${
                key === aiButtonsEnum.PHENOTYPES_IMPROVEMENTS &&
                chatRWD.isPhenotypeFinalized &&
                'd-none'
              } ${isFreeOrderLimitReached ? 'btn-disabled' : ''}
              
              blue-btn`}
              buttonAction={`${buttonsObj[key]} button clicked`}
              id={`bt-${buttonsObj[key]}-button`}
              onClick={() => onClickOfbutton(key)}
              disabled={
                (isFreeOrderLimitReached ? true : false) ||
                (key === aiButtonsEnum.PHENOTYPES_ACCURATE &&
                  (isPhenotTypeButtonDisabled || isUserInViewAsMode))
              }
            >
              <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                {key !== aiButtonsEnum.PHENOTYPES_ACCURATE ? (
                  showButtonIcons(key)
                ) : chatRWD.isPhenotypeFinalized && !hasZeroOutcomeMaxValue ? (
                  <Check />
                ) : (
                  showButtonIcons(key)
                )}
                <span className="text-capitalize">{buttonsObj[key]}</span>
              </div>
            </ButtonCustom>
          </React.Fragment>
        );
        buttons.push(button);
      }
    }

    return buttons;
  };

  const onClickOfbutton = (buttonAction) => {
    dispatch(resetChartrwdErrors());
    switch (buttonAction) {
      case aiButtonsEnum.PHENOTYPES_ACCURATE:
        dispatchOrderPageAction({
          type: 'order/setLoadingTitle',
          payload: Strings.pleaseWait,
        });

        if (!chatRWD?.isPhenotypeFinalized) {
          let uniqueModifiedParams;
          if (
            phenotypeResponse?.modified_params &&
            phenotypeResponse?.modified_params.length
          ) {
            uniqueModifiedParams = [...new Set(phenotypeResponse.modified_params)];
          }
          const payload = {
            ai_search_history_guid: PICOGuid,
            user_phenotypes: phenotypeResponse?.phenotypes,
            modified_params: uniqueModifiedParams,
            previous_order_guid: params?.guid,
            is_reorder: orderTypePath === 'reorder' ? true : false,
          };
          dispatch(
            syncModifiedPhenotypeData({
              token,
              params: payload,
            }),
          );
        }

        dispatchOrderPageAction({
          type: 'order/updateChatRWDField',
          payload: {
            field: 'isPhenotypeFinalized',
            value: !chatRWD?.isPhenotypeFinalized,
          },
        });
        break;
      case aiButtonsEnum.SUBMIT_WITH_GREEN_BUTTON:
        dispatchOrderPageAction({ type: 'order/setQuestionTypeSelection', payload: 1 });
        break;
      case aiButtonsEnum.START_OVER:
        dispatchOrderPageAction({
          type: 'order/updateChatRWDField',
          payload: {
            field: 'hasClickedOnStartOver',
            value: true,
          },
        });
        break;
      default:
        handleButtonAction({ key: buttonAction });
    }
  };

  function renderIcon(icon) {
    switch (icon) {
      case aiButtonsEnum.REGENERATE_PHENOTYPES:
        return (
          <Refresh className="cursor-pointer" onClick={() => onClickOfbutton(icon)} />
        );
      case aiButtonsEnum.MANUALLY_EDIT_PICO:
        return (
          <EditIcon
            className="cursor-pointer"
            onClick={() => {
              onClickOfbutton(icon);
            }}
          />
        );

      default:
    }
  }

  return (
    <div className="ai-worflow-container" data-testid="phenotype-section">
      <Frame>
        <Frame.Aside></Frame.Aside>
        <Frame.Body className="overflow-auto">
          <>
            {phenotypeResponse?.message && (
              <div className="w-80">{phenotypeResponse?.message}</div>
            )}
            {(phenotypeResponse &&
              Object.keys(phenotypeData).length !== 0 &&
              !isOpenAiLoading) ||
            (phenotypeData !== '' && !isOpenAiLoading) ? (
              <div>
                <div className="mb-4">
                  The following table is our AI generated list of phenotypes:
                </div>

                <Phenotype data={phenotypeResponse?.phenotypes} />

                <div className="d-flex mt-4 w-100 gap-20">
                  <div
                    className={`txt size-16 lh-28 text-gray-700 ${
                      isPhenotTypeButtonDisabled ? 'color-error' : ''
                    }`}
                  >
                    {phenotypeTableResponseTextString}
                  </div>

                  <div className="d-flex gap-5 justify-content-end flex-justify-start ml-auto pt-2 flex-items-start">
                    {!chatRWD?.isPhenotypeFinalized &&
                      phenotypeResponse?.inline_buttons?.map((icon) => (
                        <Button
                          icon={renderIcon(icon)}
                          title={renderIconTitle({ icon })}
                          key={icon}
                          text
                          buttonaction={`${renderIconTitle({ icon })} button clicked`}
                        />
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        </Frame.Body>
      </Frame>
      <Frame.Footer>
        <div
          className={`d-flex flex-wrap flex-justify-end gap-10 ${
            !isOpenAiLoading && (phenotypeData !== undefined || phenotypeData !== '')
              ? 'd-md-flex'
              : 'd-none'
          }`}
        >
          {isPhenotTypeButtonDisabled ? (
            <>
              {Object.entries(phenotypeDisabledButtons).map(([key, value]) => (
                <React.Fragment key={value}>
                  <Button
                    outlined
                    label={value}
                    id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                    onClick={() => onClickOfbutton(key)}
                    icon={showButtonIcons(key)}
                  />
                </React.Fragment>
              ))}
            </>
          ) : (
            showPhenotypeResponseButtons()
          )}
        </div>
      </Frame.Footer>

      {isFreeOrderLimitReached && (
        <div className="txt text-danger size-12 mt-2">
          You have completed your no cost ChatRWD requests. Please contact{' '}
          <a href={`mailto:${labels.ahSalesEmail}`} className="txt text-danger size-12">
            {labels.ahSalesEmail}
          </a>{' '}
          to upgrade your account.
        </div>
      )}
    </div>
  );
}

export default PhenotypePage;
