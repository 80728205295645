import axios from 'axios';
import * as user from './user';
import * as order from './order';
import * as searchOrder from './searchOrder';
import * as subscription from './subscription';
import * as notification from './notification';
import * as viewAs from './viewAs';

axios.defaults.baseURL = process.env.REACT_APP_API_PATH;

// PORTAL-1815 - send X-session-id in every request
let xsessionid = sessionStorage.getItem('xsessionid');
if (!xsessionid) {
  const uuid = crypto.randomUUID();
  sessionStorage.setItem('xsessionid', uuid);
  xsessionid = uuid;
}

axios.defaults.headers.common['X-session-id'] = xsessionid;

let storedViewAsUser;
const item = sessionStorage.getItem('viewAsSession');
if (item && item !== 'undefined') {
  storedViewAsUser = JSON.parse(item);
} else {
  storedViewAsUser = {};
}

if (
  storedViewAsUser &&
  storedViewAsUser?.impersonate_header &&
  storedViewAsUser?.user?.email
) {
  axios.defaults.headers.common['X-Impersonate-Email'] = storedViewAsUser?.user?.email;
}

export { user, order, searchOrder, subscription, notification, viewAs };
