import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Frame } from 'components/Frame';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { labels } from 'constants/labels';
import { Button } from 'components/button';
import StudyFormInputs from './StudyFormInputs';
import CrossSectional from './CrossSectional';
import { StudyFormats } from '../studyformat';
import { ReactComponent as Close } from 'assets/images/Close.svg';

const StudyForm = ({ onFormSubmit, onCancel, ...props }) => {
  const { studyDetails, aIResponseErrors } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const {
    ordersPageData: { chatRWD },
    isUserInViewAsMode,
  } = useOrdersPagedata();
  const { selectedStudyFormat } = chatRWD;
  const {
    register,
    unregister,
    handleSubmit,
    formState: { isDirty },
    clearErrors,
    setError,
    control,
  } = useForm();

  useEffect(() => {
    return () => {
      studyDetails.forEach((format) =>
        format.fields.forEach((field) => unregister(field.name)),
      );
    };
  }, [selectedStudyFormat]);

  const fields =
    (studyDetails &&
      studyDetails.length &&
      studyDetails.find((format) => format.value === selectedStudyFormat).fields) ||
    [];

  const renderStudyDetailsForm = () => {
    switch (selectedStudyFormat) {
      case labels.crossSectional:
        return (
          <CrossSectional
            fields={fields}
            clearErrors={clearErrors}
            setError={setError}
            control={control}
            {...props}
          />
        );

      default:
        return (
          <StudyFormInputs
            fields={fields}
            clearErrors={clearErrors}
            setError={setError}
            control={control}
            {...props}
          />
        );
    }
  };

  function onSubmit(values) {
    onFormSubmit(values);
  }

  function handleCancel() {
    onCancel();
  }

  if (aIResponseErrors?.studyDetailsError) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Frame>
        <Frame.Aside></Frame.Aside>
        <Frame.Body>
          <div className="d-flex flex-column gap-30">
            <div className="d-flex justify-content-between gap-20">
              <div className="txt size-16 lh-22 text-gray-80">{props.title}</div>
              <Close className="cursor-pointer" onClick={handleCancel} />
            </div>

            <StudyFormats />

            <div className="d-flex flex-column gap-15">{renderStudyDetailsForm()}</div>
          </div>
        </Frame.Body>
      </Frame>
      <Frame.Footer>
        <div className="d-flex flex-wrap flex-md-row-reverse gap-10">
          <Button
            outlined
            label="Cancel"
            type="reset"
            id={props.cancelBtnId}
            buttonaction={props.cancelBtnAction}
            onClick={handleCancel}
          />
          <Button
            outlined
            label="Save"
            type="submit"
            id={props.saveBtnId}
            buttonaction={props.saveBtnAction}
            disabled={isUserInViewAsMode || !isDirty}
          />
        </div>
      </Frame.Footer>
    </form>
  );
};

export default StudyForm;
