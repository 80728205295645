import React, { useState } from 'react';
import CustomModal from './CustomModal';
import AuthLoader from './AuthLoader';
import PrognostorgramOrderDetails from './PrognostorgramOrderDetails';
import PicotOptions from './PicotOptions';
import { ButtonCustom } from './form-input/Button';
import { VscChromeClose } from 'react-icons/vsc';
import { dateFormat } from 'utils/dateFormats';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Strings } from 'constants/Strings';
import { getCaseOrSearchID } from 'utils/getCaseOrSearchID';

function OrderdetailModal({
  modalRef = null,
  isOpen = false,
  isLoading,
  data,
  error,
  tabIndex = 0,
  questionData,
  pdfFile,
  filterBySpecialty = () => {},
  filterByStatus = () => {},
  filterByDataSet = () => {},
  filterByMotivation = () => {},
  onModify = () => {},
  onEdit = () => {},
  onCancelOrder = () => {},
  OnClickOfOrderClose = () => {},
}) {
  const returnBgColor = (question_type) => {
    var bgColor = '';
    if (questionData) {
      for (let i = 0; i < questionData?.length; i++) {
        if (questionData[i].name === question_type) {
          bgColor = questionData[i].style.backgroundColor;
        }
      }
    }
    return bgColor;
  };

  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={() => OnClickOfOrderClose(data?.is_owner)}
      cssClass="white-overlay"
      modalname="Order modal"
      overlayCssClass={'order-modal-overlay'}
    >
      <div ref={modalRef} className={`email-modal p-3 order-modal`}>
        {isLoading ? (
          <div className="position-relative">
            <AuthLoader fullScreen={false} />
          </div>
        ) : (
          <>
            {data && Object.keys(data).length !== 0 ? (
              <div className="single-prognostorm">
                <div className="title mt-1 mb-3 pr-6 pr-md-6" id="order-title-0">
                  {data.picot_status === true
                    ? data.clinical_questions
                      ? data.clinical_questions
                      : data.clinical_questions
                    : data.question_title}
                </div>
                <PrognostorgramOrderDetails
                  uuid={getCaseOrSearchID(data)}
                  email={data.email_address ? data.email_address : ''}
                  date={
                    tabIndex === 0
                      ? dateFormat(data.order_date)
                      : dateFormat(data.order_completed_date)
                  }
                  tag={data.question_type}
                  uniqueKey={`order--${data.modified_date}`}
                  color={returnBgColor(data.question_type)}
                  questionStatus={data.status}
                  tabIndex={tabIndex}
                  filterBySpecialty={filterBySpecialty}
                  filterByStatus={filterByStatus}
                  filterByDataSet={filterByDataSet}
                  filterByMotivation={filterByMotivation}
                  specialtyTag={data.provider_specialty}
                  dataSource={data.data_source}
                  motivations={data.motivations}
                  dataSourceDetails={
                    data?.data_source_object.name || data?.data_source_object?.dataset_name ? data.data_source_object : undefined
                  }
                  order={data}
                />
                <PicotOptions
                  orderGuid={data.guid}
                  population={data.population}
                  intervention={data.intervention}
                  control={data.control}
                  outcome={data.outcome}
                  timeframe={data.timeframe}
                  onModify={onModify}
                  onEdit={onEdit}
                  questionStatus={data.status}
                  onCancelOrder={onCancelOrder}
                  tabIndex={tabIndex}
                  pdfFile={pdfFile}
                  conclusion={data.conclusion}
                  summary={data.summary}
                  order={data}
                />
              </div>
            ) : (
              ''
            )}
            {error && (
              <div className="d-flex error-container my-4">
                <div className="mr-3 flex-self-center">
                  <RiErrorWarningFill className="d-block" />
                </div>
                <div className="flex-self-center">
                  {Strings.accessErrorMessage}{' '}
                  <a href="mailto:support@atroposhealth.com">{Strings.supportEmail}</a>
                </div>
              </div>
            )}

            <ButtonCustom
              cssClass="close-btn color-white"
              onClick={() => OnClickOfOrderClose(data?.is_owner)}
              buttonAction={'Modal closed button clicked'}
              id={'btn-modal-closed-button'}
            >
              <VscChromeClose />
            </ButtonCustom>
          </>
        )}
      </div>
    </CustomModal>
  );
}

export default OrderdetailModal;
